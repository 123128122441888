import React, {forwardRef, useEffect, useRef, useState} from "react"
import classNames from "classnames";
import {Button_Right_Arrow, Zoom_In, Zoom_In_White} from "../assets/svg";
import {GetBookInformations} from "../services/SpeakingLesson/SpeakingLessonService";
import { motion, AnimatePresence } from "framer-motion";

const toggleModal = (ref, overlayRef) => {
    ref.current.classList.add('hidden');
    overlayRef.current.classList.add('hidden');
}

const toggleVideoModal = (ref, overlayRef, srcRef) => {
    //save video url for after close modal
    const videoUrl = srcRef.current.src;
    srcRef.current.src = "";
    ref.current.classList.add('hidden');
    overlayRef.current.classList.add('hidden');
    //set this address again for the same video url address click
    srcRef.current.src = videoUrl;
}

export const ModalOverlay = forwardRef((props, ref) => {
    return (
        <>
            <div ref={ref} id="overlay" className="fixed hidden z-[42] w-screen h-screen inset-0 bg-[#333333] bg-opacity-70"></div>
        </>
    )
})

export const Modal = forwardRef((props, ref) => {

    const htmlMessage = props?.message?.replace(/(?:\r\n|\r|\n)/g, '<br /><br />') || '';

    return (
        <>
            <div ref={ref} id="modal"
                className="transition-transform duration-300 fixed hidden z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] sm:w-[500px] bg-white rounded-[10px] px-2 sm:px-8 py-6 space-y-5 drop-shadow-lg">
                <div className="flex justify-center">
                    <p className="font-bold text-xl">{props.title}</p>
                </div>
                <div id="modal_content" className="py-5 text-[14px] text-center">
                    <div className="mb-4" dangerouslySetInnerHTML={{ __html: htmlMessage }} />
                </div>
                {props.overlayRef && (
                    <button onClick={() => toggleModal(ref, props.overlayRef)}
                        className="flex justify-center items-center fixed right-3 -top-1 w-6 h-6 opacity-40 hover:opacity-100 before:fixed before:bg-[#333333] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:fixed after:content-[''] after:bg-[#333333] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45">
                    </button>)
                }
            </div>
        </>
    )
})

export const ModalOneButton = forwardRef((props, ref) => {

    const { showCancelButton = true } = props;
    const htmlMessage = props?.message?.replace(/(?:\r\n|\r|\n)/g, '<br /><br />') || '';

    return (
        <>
            <div ref={ref} id="modal"
                className="transition-transform duration-300 fixed hidden z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] sm:w-[500px] bg-white rounded-[10px] px-2 sm:px-8 py-6 space-y-5 drop-shadow-lg">
                <div className="flex justify-center">
                    <p className="font-bold text-xl">{props.title}</p>
                </div>
                <div id="modal_content" className="py-5 text-[14px] text-center">
                    <div className="mb-4" dangerouslySetInnerHTML={{ __html: htmlMessage }} />
                </div>
                <div className="flex flex-col items-center btn-outer-div">
                    <button onClick={props.buttonClick} className="button button-mt secondary-button w-full">{props.buttonText}</button>
                </div>
                {(props.overlayRef && showCancelButton) && (
                    <button onClick={() => { toggleModal(ref, props.overlayRef); (typeof props.closeClick !== "undefined" && props?.closeClick()); }}
                        className="flex justify-center items-center fixed right-3 -top-1 w-6 h-6 opacity-40 hover:opacity-100 before:fixed before:bg-[#333333] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:fixed after:content-[''] after:bg-[#333333] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45">
                    </button>)
                }
            </div>
        </>
    )
})

export const ModalTwoButtons = forwardRef((props, ref) => {

    const { showCancelButton = true, closeButtonClick = () => {}, isDisableButtonOne = false, isDisableButtonTwo = false } = props;
    const htmlMessage = props?.message?.replace(/(?:\r\n|\r|\n)/g, '<br /><br />') || '';

    const [clickedButton, setClickedButton] = useState(null);

    const handleButtonClick = (buttonNumber, action) => {
        setClickedButton(buttonNumber);
        action();
    };

    return (
        <>
            <div ref={ref} id="modal"
                className="transition-transform duration-300 fixed hidden z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] sm:w-[500px] bg-white rounded-[10px] px-2 sm:px-8 py-6 space-y-5 drop-shadow-lg">
                <div className="flex justify-center">
                    <p className="font-bold text-xl">{props.title}</p>
                </div>
                <div id="modal_content" className="py-5 text-[14px] text-center">
                    <div className="mb-4" dangerouslySetInnerHTML={{ __html: htmlMessage }} />
                </div>
                <div className="flex flex-col items-center btn-outer-div">
                    <button 
                        onClick={() => handleButtonClick(1, props.buttonClick1)}
                        className={classNames("button button-mt secondary-button w-full", {
                            "opacity-25 disabled:pointer-events-none": isDisableButtonOne && clickedButton === 1
                        })}
                        disabled={isDisableButtonOne}
                    >
                        {(isDisableButtonOne && clickedButton === 1) ? props.disableText : props.buttonText1}
                    </button>
                    <button 
                        onClick={() => handleButtonClick(2, props.buttonClick2)}
                        className={classNames("button button-mt secondary-button-outline w-full", {
                            "opacity-25 disabled:pointer-events-none": isDisableButtonTwo && clickedButton === 2
                        })}
                        disabled={isDisableButtonTwo}
                    >
                        {(isDisableButtonTwo && clickedButton === 2) ? props.disableText : props.buttonText2}
                    </button>
                </div>
                {(props.overlayRef && showCancelButton) && (
                    <button onClick={() => { toggleModal(ref, props.overlayRef); closeButtonClick(); }}
                        className="flex justify-center items-center fixed right-3 -top-1 w-6 h-6 opacity-40 hover:opacity-100 before:fixed before:bg-[#333333] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:fixed after:content-[''] after:bg-[#333333] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45">
                    </button>)
                }
            </div>
        </>
    )
})

export const ModalYoutube = forwardRef((props, ref) => {

    const srcRef = useRef();

    return (
        <>
            <div ref={ref} id="modal"
                className="transition-transform duration-300 fixed hidden z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-[70%] max-w-[1000px] bg-transparent rounded-[10px] px-2 sm:px-8 py-6 space-y-5 drop-shadow-lg">
                <div className="flex justify-center">
                    <p className="font-bold text-xl">{props.title}</p>
                </div>
                {props.videoUrl?.length > 0 && 
                    <div id="modal_content" className="py-5 text-[14px] text-center">
                        <figure className="rounded-[10px] aspect-video">
                            <iframe allowFullScreen className="rounded-[10px] w-full aspect-video" ref={srcRef} src={props.videoUrl}></iframe>
                        </figure>
                    </div>
                }
                {props.overlayRef && (
                    <button 
                        onClick={() => {
                            toggleVideoModal(ref, props.overlayRef, srcRef);
                            props.closeAction();
                        }}
                        className="flex justify-center items-center fixed right-1 top-4 w-6 h-6 opacity-80 hover:opacity-100 before:fixed before:bg-[#ffff] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:fixed after:content-[''] after:bg-[#ffff] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45">
                    </button>)
                }
            </div>
        </>
    )
})

export const BookModal = forwardRef((props, ref) => {

    const { src, timeoutState, toggleModal } = props;

    const [type, setType] = useState("");

    //Linkten kitap adını ayrıştırır
    const seperateBookNameFromLink = (link) => {
        return link.split("book=")[1];
    }

    const bookName = seperateBookNameFromLink(src) || false;

    useEffect(() => {
        if(bookName){
            GetBookInformations(bookName)
                .then(result => {
                    if(result.status === 200) {
                        let resultContent = JSON.parse(result.content);
                        setType(type => resultContent.bookType);
                    }
                })
        }
    }, [bookName]);

    const dynamicSrc = `https://books.clickivo.com/books_modal.php?comp=${+process.env.REACT_APP_BRAND_ID}&book=${bookName}`;

    return (
        <div className="fixed h-full z-30 top-0 w-full left-0 drop-shadow-lg hidden" id="modal" ref={ref}>
            <div className="h-full flex items-center justify-center p-9">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-[#333333] bg-opacity-50"></div>
                </div>
                <div className={classNames("relative rounded-[10px]", {
                    "w-[89%] h-[calc(100vh-64px)]" : type === "PDF",
                    "overflow-y-scroll md:w-[60%] max-h-[90%]" : type === "JPG"
                })}>
                    {type === "JPG" && (
                        <div className="shadow-xl">
                            <img className="w-full rounded-[10px]" src={dynamicSrc} alt="" />

                            <a href={dynamicSrc} target="_blank" className="absolute inset-0 z-10 flex justify-center items-center" rel="noreferrer">
                                <img
                                    src={Zoom_In}
                                    width="64"
                                    height="64"
                                    className={`${timeoutState ? 'opacity-100 scale-100' : 'opacity-0 scale-150'} duration-1000 transition-all`}
                                    alt=""
                                />
                            </a>
                        </div>
                    )}

                    {type === "PDF" && (
                        <div className="relative">
                            <iframe className="rounded-[10px] h-[calc(100vh-64px)]" width="100%" src={dynamicSrc} alt="book"/>
                            <button
                                type="button"
                                className="right-36 top-[17px] absolute hidden sm:block"
                                onClick={() => window.open(dynamicSrc, "_blank")}
                            >
                                <img
                                    src={Zoom_In_White}
                                    width={20}
                                    height={20}
                                    alt="zoom-in-white"
                                ></img>
                            </button>
                        </div>
                    )}

                    <button
                        onClick={toggleModal}
                        className="flex justify-center group items-center absolute -top-6 -right-8 w-6 h-6 before:fixed before:bg-transparent before:content-[''] before:h-6 before:w-6"
                    >
                        <span className="relative">
                            <span className=" absolute -top-3 -left-[1px] before:fixed before:bg-[#e5e5e5] before:group-hover:bg-white before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:fixed after:content-[''] after:bg-[#e5e5e5] after:group-hover:bg-white after:rounded-full after:h-6 after:w-[2px] after:-rotate-45"/>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
})

export const ModalFullScreen = (props) => {
    return (
        <>
            <div id="overlay" className="fixed z-40 w-full inset-0 bg-[#333333] bg-opacity-[97%]">
                <button onClick={props.handleCloseClick} className="absolute sm:top-2 top-1 z-50 right-3 mt-2 mr-2 text-white focus:outline-none">
                    <svg className="h-10 w-10 stroke-current text-white rounded-full p-1 border border-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                {props.children}
            </div>
        </>
    )
}

export const ModalFullScreenWhite = (props) => {
    return (
        <>
            <div className="fixed z-40 w-full h-full inset-0 bg-white overflow-y-auto">

                <button onClick={props.handleCloseClick} className="absolute navbar-btn sm:top-2 top-1 left-3 mt-2 mr-2 text-black focus:outline-none">
                    <img src={Button_Right_Arrow} alt="button-right-arrow" />
                </button>
                {props.children}
            </div>
        </>
    )
}

export const ModalContent = forwardRef((props, ref) => {

    const {
        show,
        children,
        overlayRef,
        closeAction,
        showCloseButton = true,
        className = "sm:w-[600px] sm:h-[600px]"
    } = props;

    const handleClose = () => {
        toggleModal(ref, overlayRef);
        closeAction();
    }

    return (
        show &&
            <div
                ref={ref}
                className={classNames("transition-transform duration-300 fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] sm:w-[500px] bg-white rounded-[10px] px-2 sm:px-8 py-6 space-y-5 drop-shadow-lg", {
                    [className]: [!!className]
                })}
            >
                {children}
                {(overlayRef && showCloseButton) && (
                    <button
                        onClick={handleClose}
                        className="flex justify-center items-center fixed right-3 -top-1 w-6 h-6 opacity-40 hover:opacity-100 before:fixed before:bg-[#333333] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:fixed after:content-[''] after:bg-[#333333] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45"
                    ></button>
                )}
            </div>
    );
})

export const ModalFullScreenSlide = ({ children, handleCloseClick }) => {
    const slideUpVariants = {
        initial: {
            y: "100%",
            transition: {
                type: "spring",
                damping: 40,
                stiffness: 300,
            }
        },
        animate: {
            y: 0,
            transition: {
                type: "spring",
                damping: 30,
                stiffness: 300,
            }
        },
        exit: {
            y: "100%",
            transition: {
                type: "spring",
                damping: 40,
                stiffness: 300,
            }
        }
    };

    return (
        <motion.div
            key="modal-container"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={slideUpVariants}
            className="fixed z-40 w-full h-full inset-0 bg-white will-change-transform overflow-hidden"
        >
            <div className="relative h-full">
                <button 
                    id="modal-back-button"
                    onClick={handleCloseClick} 
                    className="absolute z-10 navbar-btn sm:top-2 top-1 left-3 mt-2 mr-2 text-black focus:outline-none"
                >
                    <img src={Button_Right_Arrow} alt="button-right-arrow" />
                </button>
                <div className="h-full">
                    {children}
                </div>
            </div>
        </motion.div>
    );
};