import {forwardRef, useRef, useState} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {Demo, Logout, Mentor, Permission, Profile, Reference, Support} from "../assets/svg";
import useAnalytics from "../hooks/useAnalytics";
import useAuth from "../hooks/useAuth";
import {useLocalization} from "../hooks/useLocalization";
import {url} from "../routes/utility";
import {CheckMemberDemoRight} from "../services/MemberDemoRight/MemberDemoRightServices";
import {memberLogout} from "../store/MemberSlice";
import {closeModal, openModal} from "../utils";
import ChangeLanguage from "./ChangeLanguage";
import {EventLogs, ServiceErrorMessages} from "./Constants";
import {ModalOneButton, ModalOverlay} from "./Modals";
import useMemberUsername from "../hooks/useMemberUsername";
import {DemoAutoTeacher} from "../pages/Demo";
import useDemoAvailability from "../hooks/useDemoAvailability";

const closeCollapse = (collapseRef, collapseOverlayRef) => {
    collapseRef.current.classList.toggle("translate-x-[250px]")
    collapseOverlayRef.current.classList.toggle("hidden")
}

export const Collapse = forwardRef((props, ref) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const username = useMemberUsername(member?.MemberId);
    const demoAvailability = useDemoAvailability(member?.MemberId);
    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const memberId = member?.MemberId;
    const landlineNumber = '02122860486';

    const modalRef = useRef();
    const modalOverlayRef = useRef();
    const modalAutoDemoTeacherSelectionRef = useRef();

    const [modalMessage, setModalMessage] = useState({});
    const [showAutoDemoTeacherSelectionModal, setShowAutoDemoTeacherSelectionModal] = useState(false);

    const checkMemberDemoRight = async () => {

        const result = await CheckMemberDemoRight(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            if (resultContent) {
                setShowAutoDemoTeacherSelectionModal(true);
                openModal(modalOverlayRef, modalAutoDemoTeacherSelectionRef);
                handleClose();
            } else {
                setModalMessage({
                   text: strings.menu.collapse.modal.no_demo_description,
                   buttonText: strings.menu.collapse.modal.contact_mentor,
                   navigate: true
                });
                openModal(modalOverlayRef, modalRef);
            }
        } else {
            if(result.content === ServiceErrorMessages.ALREADY_ACTIVE_DEMO)
                setModalMessage({
                    text: strings.menu.collapse.modal.already_have_active_demo,
                    buttonText: strings.modal.okey,
                    navigate: false
                });
            else
                setModalMessage({
                    text: strings.general_information_messages.an_error_occured,
                    buttonText: strings.modal.okey,
                    navigate: false
                });
            openModal(modalOverlayRef, modalRef);
        }
    }

    const handleClose = () => {
        if (props.isCollapseOpen === true) {
            closeCollapse(ref, props.overlayRef)
        }
        props.setIsCollapseOpen(false);
    }

    return (
        <>
            <OutsideClickHandler onOutsideClick={handleClose}>
                <div ref={ref} className="fixed z-50 h-full bg-white w-[250px] right-0 top-0 py-4 px-5 transition-transform duration-500 translate-x-[250px]">
                    <div className="flex flex-col h-full pt-10">
                        <div className="flex flex-col gap-y-4">
                            {/* Profil */}
                            <Link
                                to={`/profile/${username}`}
                                className="flex gap-2 group"
                            >
                                <img src={Profile} width="26" height="26" alt="profile" />
                                <p>{strings.menu.collapse.profile}</p>
                            </Link>
                            {/* Destek */}
                            <Link
                                to={url("support")}
                                className="flex gap-2 group"
                            >
                                <img src={Support} width="26" height="26" alt="support" />
                                <p>{strings.menu.collapse.support}</p>
                            </Link>
                            {/* İzinler */}
                            <Link
                                to={url("permissions")}
                                className="flex gap-2 group"
                            >
                                <img src={Permission} width="26" height="26" alt="permissions" />
                                <p>{strings.permissions.title}</p>
                            </Link>
                            {/* Mentor */}
                            <Link
                                to={url("mentor")}
                                className="flex gap-2 group"
                            >
                                <img src={Mentor} width="26" height="26" alt="mentor" />
                                <p>{strings.mentor.title}</p>
                            </Link>
                            {/* Reference */}
                            <Link
                                to={url("learntogether.reference")}
                                className="flex gap-2 group"
                            >
                                <img src={Reference} width="26" height="26" alt="reference" />
                                <p>{strings.menu.collapse.reference}</p>
                            </Link>
                            {/* Demo */}
                            {demoAvailability &&
                                <button
                                    className="flex gap-2 group"
                                    onClick={checkMemberDemoRight}
                                >
                                    <img src={Demo} width="26" height="26" alt="demo" />
                                    <p>{strings.menu.collapse.demo}</p>
                                </button>
                            }
                        </div>
                        <div className="mt-auto">
                            <div className="mb-5">
                                <span className="font-bold">{strings.menu.collapse.contact_us}:</span>{" "}
                                <a className="underline text-primary" href={`tel:${landlineNumber}`}>0212 286 04 86</a>
                            </div>
                            <div className="flex justify-between">
                                <button
                                    onClick={() => {
                                        analytics.useAnalytics(EventLogs.LOGOUT);
                                        dispatch(memberLogout());
                                    }}
                                    className="flex gap-2 group">
                                    <img src={Logout} width="26" height="26" alt="logout" />
                                    <p>{strings.menu.collapse.logout}</p>
                                </button>
                                {props.overlayRef && (
                                    <button onClick={() => { closeCollapse(ref, props.overlayRef); props.setIsCollapseOpen(false); }} className="navbar-btn flex fixed top-5 right-5">
                                        <span className="material-symbols-outlined text-[28px]">keyboard_arrow_right</span>
                                    </button>
                                )}
                                <ChangeLanguage theme="dark" toTop="true" />
                            </div>
                            <div className="mr-5 text-end text-[15px] text-passive">
                                <p>v4.4.0</p>
                            </div>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage?.text}
                buttonText={modalMessage?.buttonText}
                buttonClick={() => {
                    modalMessage?.navigate && navigate(url("mentor"));
                    closeModal(modalOverlayRef, modalRef);
                }}
            />

            {showAutoDemoTeacherSelectionModal &&
                <DemoAutoTeacher
                    ref={modalAutoDemoTeacherSelectionRef}
                    overlayRef={modalOverlayRef}
                    closeAction={() => {
                        setShowAutoDemoTeacherSelectionModal(false);
                        closeModal(modalOverlayRef, modalAutoDemoTeacherSelectionRef);
                    }}
                />
            }
        </>
    );
})

export const CollapseOverlay = forwardRef((props, ref) => {
    return (
        <>
            <div ref={ref} id="overlay" className="fixed hidden z-40 w-screen h-screen inset-0 bg-[#333333] bg-opacity-70"></div>
        </>
    )
})