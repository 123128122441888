import React, {useEffect, useState} from 'react';
import '../../../assets/css/chart.css';
import {ComponentRenderGroups, ComponentRenderKeys, DailyLessonType} from '../../../components/Constants';
import {TermDropDown} from '../../../components/TermDropDown';
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import {useLocalization} from '../../../hooks/useLocalization';
import {getMemberType} from '../../../services/Auth/AuthService';
import {GetSpeakingLessonDetailService} from "../../../services/SpeakingLesson/SpeakingLessonService";
import {formatNoneZeroDateToZeroDateForDash, getLanguageIdByLanguageKey, getTodayForLessonDate, getyyyyMMddDate2} from '../../../utils';
import {Attended, Frozen, Holiday, Inactive, NoAnswer, Pending, Postponed} from '../components';
import DailyLessonNonUser from './DailyLessonNonUser';
import {useDispatch, useSelector} from "react-redux";
import {updateRenderStatus} from "../../../store/ComponentRenderSlice";
import classNames from "classnames";
import { ChooseDate, MissedCallInformation } from '../components/Common';
import { Loading } from '../../../components';

const DailyLessonHome = () => {

    const strings = useLocalization();
    const { member, token } = useAuth();
    const dispatch = useDispatch();
    const attendedRenderStatus = useSelector(state => state.componentRenderStore.DailyLesson.Attended);
    const memberType = getMemberType(member.Status);
    const langauge = useSelector(state => state.localizationStore.language);

    const terms = (typeof member.Terms !== "undefined") && JSON.parse(member.Terms);
    const analytics = useAnalytics();

    //those are for calendar
    const today = getTodayForLessonDate();
    const [title, setTitle] = useState('');
    //Kullanıcı eğer termScheduleDate ile gelmişse o tarihi seçili tarih olarak atıyoruz. Aksi taktirde bugünün tarihini seçili tarih olarak atıyoruz.
    const [selectedDate, setSelectedDate] = useState(localStorage.getItem("selectedDate") ? localStorage.getItem("selectedDate") : today);
    const [termId, setTermId] = useState(false);

    const [screenData, setScreenData] = useState(false);
    const [loading, setLoading] = useState(false);

    const getLesson = async () => {
        setLoading(true);

        const result = await GetSpeakingLessonDetailService(selectedDate, termId, token, getLanguageIdByLanguageKey(langauge));

        if (result.statusCode === 200) {
            setScreenData(JSON.parse(result.message));
        } else {
            if (result.message)
                analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
            else {
                analytics.useAnalytics('Error_Occured', {
                    error: `An unexpected error occurred.`,
                });
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (screenData) {
            if (screenData.dailyLessonType === DailyLessonType.Attended ||
                screenData.dailyLessonType === DailyLessonType.NoAnswer ||
                screenData.dailyLessonType === DailyLessonType.Missed) {
                setTitle(title => strings.speaking_lesson.daily_lesson.daily_lesson_title_attended);
            } else {
                setTitle(title => strings.speaking_lesson.daily_lesson.daily_lesson_title);
            }
        }
    }, [screenData])

    //from child dropdown
    const changeTerm = term => {
        setTermId(term.TermId);
    };

    //if termId is selected and date is a valid date
    useEffect(() => {
        if (selectedDate && termId && terms) {
            localStorage.getItem('selectedDate')
                && localStorage.removeItem('selectedDate');
            getLesson();
        }
    }, [selectedDate, termId, langauge])


    const getDailyScreen = () => {
        if (screenData) {
            switch (screenData.dailyLessonType) {
                case DailyLessonType.Active:
                    return <Pending data={screenData} selectedDate={selectedDate} />
                case DailyLessonType.Attended:
                    return <Attended data={screenData} selectedDate={selectedDate} />
                case DailyLessonType.NoAnswer:
                    return <NoAnswer data={screenData} selectedDate={selectedDate} />
                case DailyLessonType.Missed:
                    return <NoAnswer data={screenData} selectedDate={selectedDate} />
                case DailyLessonType.Postponed:
                    return <Postponed />;
                case DailyLessonType.Frozen:
                    return <Frozen data={screenData} />
                case DailyLessonType.NoLesson:
                    return <Holiday />;
                case DailyLessonType.Holiday:
                    return <Holiday />;
                case DailyLessonType.NoActiveTerm:
                    return <Inactive />;
                default:
                    return <Holiday />;
            }
        }
    }

    useEffect(() => {
        if(screenData?.dailyLessonType === DailyLessonType.Attended && !attendedRenderStatus)
            dispatch(updateRenderStatus({
                group: ComponentRenderGroups.DAILY_LESSON,
                key: ComponentRenderKeys.ATTENDED,
                value: true
            }));

        if(screenData?.dailyLessonType !== DailyLessonType.Attended && attendedRenderStatus)
            dispatch(updateRenderStatus({
                group: ComponentRenderGroups.DAILY_LESSON,
                key: ComponentRenderKeys.ATTENDED,
                value: false
            }));

    }, [screenData])

    return (
        <>
            <div className="main-content">
                {memberType === 'Candidate' && !terms ? (<DailyLessonNonUser />)
                    : (<>
                            <ChooseDate
                                title={strings.speaking_lesson.daily_lesson.choose_date}
                                // 2023-11-8 formatında gelen tarihler 2023-11-08 olarak formatlanır
                                sDate={formatNoneZeroDateToZeroDateForDash(selectedDate)}
                                setDate={setSelectedDate}
                            />

                            {(screenData && screenData?.activeMissedCall !== null) && (
                                <MissedCallInformation
                                    data={screenData?.activeMissedCall}
                                    showButton={selectedDate !== getyyyyMMddDate2(screenData?.activeMissedCall?.missedDate)}
                                />
                            )}

                            <div
                                className={classNames("mt-5", {
                                    "gray-section" : screenData?.dailyLessonType === DailyLessonType.Attended
                                        || screenData?.dailyLessonType === DailyLessonType.Active
                                        || screenData?.dailyLessonType === DailyLessonType.Missed,
                                    "gray-section-top" : screenData?.dailyLessonType !== DailyLessonType.Attended
                                        && screenData?.dailyLessonType !== DailyLessonType.Active
                                        && screenData?.dailyLessonType !== DailyLessonType.Missed
                                })}
                            >
                                {/* Günün Konuşma Dersi başlığı kaldırıldı. */}
                                {/* {screenData?.dailyLessonType !== DailyLessonType.Attended &&
                                    <h5 className="text-primary mb-2">{title}</h5>
                                } */}
                                <TermDropDown terms={terms} changeTerm={changeTerm}/>
                            </div>

                            {loading && <Loading />}

                            {!loading && 
                                getDailyScreen()
                            }
                        </>
                    )}

                <div className="left-margin-bottom"></div>
            </div>
        </>
    )
}

export default DailyLessonHome