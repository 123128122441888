import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConversationBubble } from "../../../../assets/svg";
import { Loading } from "../../../../components";
import { AIKeys, ConversationalAILogTitles, EventLogs } from "../../../../components/Constants";
import useAiRouting from "../../../../hooks/useAiRouting";
import useAnalytics from "../../../../hooks/useAnalytics";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization";
import useSpeaker from "../../../../hooks/useSpeaker";
import { url } from "../../../../routes/utility";
import { ChatbotWithTTSv3, ChatbotWithTtsTutor, Transcribe } from "../../../../services/AIUseCase/AIUseCasePythonService";
import { GetAiPromptByType } from "../../../../services/AIUseCase/AIUseCaseService";
import {
    CreateAIUseCaseSession,
    CreateAIUseCaseSessionLog,
    CreateSessionCorrections,
    GetAIUseCaseSessionLogsBySessionId
} from "../../../../services/AIUseCaseHistory/AIUseCaseHistoryService";
import { GetBookUnitAiUseCasePrompt } from "../../../../services/ConversationalAI/ConversationalAIService";
import { getLanguageIdByLanguageKey } from "../../../../utils";
import { AIChatBubble, PlaceholderBubble, RecordButton, TutorQuestion, TutorTitle, UserChatBubble } from "./components";

const IntelligentChat = ({ 
    title = "", 
    emoji = "", 
    bookUnitId = 0,
    aiUseCaseId = 0,
    // Bir session sadece readonly olarak gösterilecekse doldurulacak.
    aiUseCaseSessionId = null,
    // Bir session'a kaldığı yerden devam edilecekse doldurulacak.
    lastSessionId = null,
    // Geri butonuna tıklandığında çalışacak fonksiyon
    onBackButtonClick = null
}) => {

    const { member } = useAuth();
    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    const language = useSelector(state => state.localizationStore.language);
    const tutorAiUseCaseId = useAiRouting(AIKeys.TUTOR);
    const localLangCode = process.env.REACT_APP_LANG_TR_CODE;
    const logTitle = ConversationalAILogTitles[aiUseCaseId];

    const chatContainerRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    // AI'nin cevaplarını ve kullanıcının mesajlarını tutar.
    const [messages, setMessages] = useState([]);

    // Mikrofonun sesi aktif mi?
    const [isRecording, setIsRecording] = useState(false);

    // AI Use Case Session Id'si
    const [useCaseSessionId, setUseCaseSessionId] = useState("");
    
    // AI Use Case session tipinin tutor olup olmadığını tutar.
    const [isTutorSession, setIsTutorSession] = useState(null);

    // AI'nin cevap verirken ve kullanıcı ses kaydederken loading durumunu tutar.
    const [chatbotLoading, setChatbotLoading] = useState(false);
    const [transcribeLoading, setTranscribeLoading] = useState(false);

    // AI'nin cevaplarının sesini tutar.
    const [ttsVoice, setTtsVoice] = useState(-1);

    // AI'nin cevaplarının sesini oynatırken kullanılır.
    const [currentPlayingId, setCurrentPlayingId] = useState(null);
    const [currentAudioRef, setCurrentAudioRef] = useState(null);

    // Kullanıcı istediği mesaja geri dönmek istediğinde çalışır.
    const [isRefreshing, setIsRefreshing] = useState(false);

    // Placeholder'ın gösterilip gösterilmeyeceğini belirler.
    const [showPlaceholder, setShowPlaceholder] = useState(false);

    // İlk yüklemede loading durumunu belirler.
    const [initialLoading, setInitialLoading] = useState(true);

    // Kullanıcının yanlış yaptığı cümleleri tutar.
    const [wrongSentences, setWrongSentences] = useState([]);

    // Servisten getirilen cümle düzeltmek ve ipucu vermek için kullanılan promptlar.
    const [externalPrompts, setExternalPrompts] = useState({
        "fix-sentence": "",
        "get-sentence-clue": ""
    });

    const [pendingTranscription, setPendingTranscription] = useState(null);
    const [isWaitingForGrammarCheck, setIsWaitingForGrammarCheck] = useState(false);
    const [showExample, setShowExample] = useState(false);
    const [showClue, setShowClue] = useState(false);

    const externalPromptKeys = Object.freeze({
        "FIX_SENTENCE": "fix-sentence",
        "GET_SENTENCE_CLUE": "get-sentence-clue"
    });

    const aiRoles = Object.freeze({
        "system": 0,
        "user": 1,
        "ai": 2
    });

    // RoleId'ye göre AI'nin rolünü bulur.
    const getAiRoleByRoleId = (roleId) => {
        return Object.keys(aiRoles).find(key => aiRoles[key] === roleId);
    };

    // AI'nin cevaplarının sesini günceller.
    const updateTtsVoice = useCallback((voice) => {
        setTtsVoice(voice);
    }, []);

    // Log Mesajını replace ederek log mesajını gönderir.
    const sendLog = (log) => {
        const logMessage = log.replace("#CONVERSATIONAL_AI_TYPE#", logTitle);
        analytics.useAnalytics(logMessage);
    }

    const {
        speakSentence, 
        SpeakerComponent, 
        isSpeakingComplete, 
        stopSpeaking
    } = useSpeaker(updateTtsVoice);

    /*
       Tutor ve normal akıştakı sessionlar için fonksiyonlar.
       Bu fonksiyonlar tutor tipinde ve normal akışta farklı işlemler olduğu için yapılmaktadır.
    */

    /* Tutor için düzenlenen fonksiyonlar başlar */
    
    // Session log tarafına gönderilecek detailContent'i oluşturur.
    const getDetailContentBySessionType = (resultContent) => {
        if(!isTutorSession) {
            return {
                translation: {
                    [localLangCode]: resultContent.message[localLangCode]
                }
            };
        } else {
            const question = resultContent?.question;
            const trySaying = resultContent?.trySaying;

            const messageContent = question || trySaying || null;

            return {
                section: resultContent?.section,
                round: resultContent?.round,
                totalRound: resultContent?.totalRound,
                title: resultContent?.title ?? {
                    en: resultContent?.title?.en,
                    [localLangCode]: resultContent?.title?.[localLangCode]
                },
                clue: resultContent?.clue ?? {
                    en: resultContent?.clue?.en,
                    [localLangCode]: resultContent?.clue?.[localLangCode]
                },
                translation: messageContent ?? {
                    en: messageContent?.en,
                    [localLangCode]: messageContent?.[localLangCode]
                }
            };                   
        }
    };

    // AI Tipine göre message'ı döndürür.
    const getMessageResponseBySessionType = (resultContent) => {
        if(!isTutorSession) {
            return {
                message: resultContent.message.en,
                translation: resultContent.message[localLangCode],
                endOfPrompt: resultContent.endOfPrompt || false
            };
        } else {        
            const question = resultContent?.question;
            const trySaying = resultContent?.trySaying;

            if (!question && !trySaying) {
                return {
                    message: "",
                    translation: "",
                    endOfPrompt: false
                };
            }

            // Try saying'in mesajının üstüne "SÖYLEMEYİ DENE:" ekler.
            const trySayingMessage = 
                trySaying?.en ? strings.speaking_lesson.conversational_ai.try_saying : null;

            return {
                trySayingMessage: trySayingMessage,
                message: question?.en || trySaying?.en || "",
                translation: question?.[localLangCode] || trySaying?.[localLangCode] || "",
                title: resultContent?.title || null,
                section: resultContent?.section || null,
                clue: resultContent?.clue?.[localLangCode] || null,
                round: resultContent?.round || null,
                totalRound: resultContent?.totalRound || null,
                endOfPrompt: resultContent.endOfPrompt || false,
                currentState: resultContent?.currentState || "",
                sectionCompletionMessage: resultContent?.sectionCompletionMessage ?? null
            };
        }
    };

    // Tutor round gösterimi için kontrol fonksiyonu
    // Aynı soru sayılarının üst üste render olmasını önler.
    const shouldShowTutorRound = (message, index, messages) => {
        // Önceki AI mesajını bul
        const lastAiMessage = [...messages]
            .slice(0, index)
            .reverse()
            .find(m => m.type === 'ai');

        // Eğer önceki AI mesajı yoksa veya round/section değişmişse true döndür
        return !lastAiMessage?.tutor || 
                lastAiMessage.tutor.round !== message.tutor?.round ||
                lastAiMessage.tutor.section !== message.tutor?.section;
    };
    /* Tutor için düzenlenen fonksiyonlar biter */

    // Chat'i başlatmak için bir defa kullanılan fonksiyon.
    const initializeChat = async (prompt, sessionId) => {
        const initialMessages = [
            { role: "system", content: prompt }
        ];
        
        setChatbotLoading(true);
        try {
            const createdSystemPromptLogId = await createSessionLog(prompt, null, aiRoles.system, sessionId);

            const result = isTutorSession ? 
                await ChatbotWithTtsTutor(bookUnitId, ttsVoice, initialMessages) :
                await ChatbotWithTTSv3(JSON.stringify(initialMessages), ttsVoice);

            if (result.statusCode === 200) {
                if (result.message?.voice) {
                    setTtsVoice(parseInt(result.message.voice));
                }

                const resultContent = result.message.content;
                // MessageResponse tutor ve normal akışta farklı olacak. Bu sebeple fonksiyon üzerinden kontrol ediyoruz.
                const messageResponse = getMessageResponseBySessionType(resultContent);

                // AI'nin cevabını loglar. Geriye ilgili sessionLogId'yi döner.
                const sessionLogId 
                    = await createSessionLog(
                        messageResponse.message, 
                        getDetailContentBySessionType(resultContent), 
                        aiRoles.ai, 
                        sessionId, 
                        messageResponse.endOfPrompt
                    );

                // Tutor tipinde bir session ise, AI'nin cevabının title, section, round ve totalRound bilgilerini tutar.
                const tutorAiMessageSection = isTutorSession ? {
                    title: messageResponse.title,
                    section: messageResponse.section,
                    round: messageResponse.round,
                    totalRound: messageResponse.totalRound,
                    currentState: messageResponse.currentState,
                    clue: messageResponse.clue
                } : null;

                const aiMessage = {
                    id: sessionLogId,
                    type: 'ai',
                    content: messageResponse.message,
                    trySayingMessage: messageResponse.trySayingMessage,
                    translation: messageResponse.translation,
                    isLoading: false,
                    isAutoCorrect: true,
                    role: 'assistant',
                    audio: result.message.audio,
                    endOfPrompt: messageResponse.endOfPrompt,
                    tutor: tutorAiMessageSection ?? null
                };
                
                setMessages([
                    { 
                        id: createdSystemPromptLogId,
                        type: 'system',
                        content: prompt,
                        role: 'system'
                    },
                    aiMessage
                ]);
                sendLog(EventLogs.CONVERSATIONAL_AI_MESSAGE_SEND);
            } else {
                sendLog(EventLogs.CONVERSATIONAL_AI_MESSAGE_FAILED);
            }
        } catch (error) {
            sendLog(EventLogs.CONVERSATIONAL_AI_MESSAGE_FAILED);
        }
        setChatbotLoading(false);
    };

    // AI Use Case Session oluşturur.
    // Bu fonksiyon sadece yeni bir session oluşturduğunda tetiklenir.
    // Devam edilecek bir session varsa session oluşturma işlemini atlar.
    const createSession = async (memberId, aiUseCaseId) => {
        try {
            const result = await CreateAIUseCaseSession(memberId, aiUseCaseId, bookUnitId);

            if(result.statusCode === 200) {
                const sessionId = JSON.parse(result.message);
                setUseCaseSessionId(sessionId);
                return sessionId;
            }
        } catch (error) {}
    };

    // Kullanıcının mesajlarını ve AI'nin mesajlarını loglar.
    // Her mesajda gönderilen content'i ve roleId'yi loglar.
    // sessionId'yi gönderirsek, o session'a ait logları oluşturur. Yoksa, useCaseSessionId'yi kullanarak o session'a ait logları oluşturur.
    const createSessionLog = async (
        message, 
        detailContent = null,
        roleId, 
        sessionId = null, 
        conversationCompleted = false
    ) => {
        try {
            const model = {
                contentText: message,
                aiUseCaseRoleId: roleId,
                aIUseCaseSessionId: sessionId || useCaseSessionId || lastSessionId,
                isSessionCompleted: conversationCompleted,
                aiUseCaseSessionDetailContent: detailContent ? JSON.stringify(detailContent) : null
            };

            const result = await CreateAIUseCaseSessionLog(model);
            
            if(result.statusCode === 200) {
                return JSON.parse(result.message);
            } else {
                return "";
            }
        } catch (error) {}
    };

    // Kullanıcının yanlış yaptığı cümleleri servise göndermek için kullanılır.
    const createSessionCorrections = async () => {
        try {
            const model = {
                aiUseCaseSessionId: useCaseSessionId || lastSessionId,
                mistakes: wrongSentences
            };

            const result = await CreateSessionCorrections(model);

            if(result.statusCode === 200) setWrongSentences([]);
        } catch (error) {}
    };

    // Mikrofona tıklandığında çalışır.
    // Mikrofonun sesini kaydeder ve transcribe servisine gönderir.
    // Transcribe servisi kullanıcının sesini metine dönüştürür ve handleUserMessage fonksiyonuna gönderir.
    const handleStartRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            
            let audioChunks = [];
            
            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                const formData = new FormData();
                formData.append('audio', audioBlob);

                setTranscribeLoading(true);
                try {
                    const userMessage = await Transcribe(formData);
                    if (userMessage) {
                        // Önce kullanıcı mesajını ekrana basıyoruz
                        const cleanContent = userMessage.replace(/\n/g, ' ').trim();
                        const sessionLogId = await createSessionLog(cleanContent, null, aiRoles.user, null, false);
                        const userMessageObj = {
                            id: sessionLogId,
                            type: 'user',
                            content: cleanContent,
                            isAutoCorrect: true,
                            role: 'user'
                        };
                        setMessages(prev => [...prev, userMessageObj]);

                        // Grammar check için state'leri güncelliyoruz
                        setPendingTranscription(cleanContent);
                        setIsWaitingForGrammarCheck(true);
                    }
                } catch (error) {
                    setPendingTranscription(null);
                    setIsWaitingForGrammarCheck(false);
                }
                setTranscribeLoading(false);
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            sendLog(EventLogs.CONVERSATIONAL_AI_MESSAGE_FAILED);
        }
    };

    // Grammar check tamamlandığında çağrılır
    const handleGrammarCheckComplete = async (isGrammarCorrect) => {
        if (isWaitingForGrammarCheck && pendingTranscription) {
            handleUserMessage(pendingTranscription, isGrammarCorrect);
            setPendingTranscription(null);
            setIsWaitingForGrammarCheck(false);
        }
    };

    // Mikrofona tıklandığında çalışır.
    // Mikrofonun sesini durdurur.
    const handleStopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    // Mikrofona tıklandığında çalışır.
    // Mikrofonun sesi aktif ise, sesini durdurur.
    const handleRecordingStateChange = (isRecording) => {
        if (isRecording) {
            handleStartRecording();
        } else {
            handleStopRecording();
        }
    };

    // AI'nin cevabını oynatırken çalışır.
    // Oynatılan sesin durdurulmasını sağlar.
    const stopCurrentAudio = () => {
        if (currentPlayingId && currentAudioRef) {
            currentAudioRef.pause();
            currentAudioRef.currentTime = 0;
            setCurrentPlayingId(null);
            setCurrentAudioRef(null);
        }
    };

    // Kullanıcı istediği mesaja geri dönmek istediğinde çalışır.
    // Oynatılan sesin durdurulmasını sağlar.
    // Mesajların messages array'inden silinmesini sağlar.
    const handleRefresh = async (messageId) => {
        try {
            setIsRefreshing(true);
            stopCurrentAudio();
            
            // Mesaj index'ini bul
            const messageIndex = messages.findIndex(m => m.id === messageId);
            if (messageIndex === -1) return;

            // Silinecek tüm mesajları al (seçilen index'ten sonraki tüm mesajlar)
            const deletedMessages = messages.slice(messageIndex);
            
            // Mesajları güncelle - index'e göre filtrele
            setMessages(prevMessages => prevMessages.slice(0, messageIndex));

            // Silinen mesajlar arasından user mesajlarını bul ve wrong sentences'dan çıkar
            const deletedUserContents = deletedMessages
                .filter(message => message.type === 'user')
                .map(message => message.content);

            if (deletedUserContents.length > 0) {
                setWrongSentences(prev => 
                    prev.filter(sentence => !deletedUserContents.includes(sentence))
                );
            }
        } catch (error) {
            console.error('Error in handleRefresh:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    // Kullanıcının mesajını işler.
    // Content'i temizler ve AI'ye gönderir.    
    const handleUserMessage = async (content, checkUserAnswerStatus = null) => {
        if (isRefreshing) return;
        
        setChatbotLoading(true);
        try {
            const messageHistory = messages
                .map(msg => ({
                    role: msg.role,
                    content: msg.content,
                    ...(msg.tutor && { 
                        section: msg.tutor.section,
                        round: msg.tutor.round,
                        totalRound: msg.tutor.totalRound,
                        currentState: msg.tutor.currentState,
                        endOfPrompt: msg.tutor.endOfPrompt
                    })
                }));

            const result = isTutorSession ? 
                await ChatbotWithTtsTutor(bookUnitId, ttsVoice, messageHistory, content, checkUserAnswerStatus) :
                await ChatbotWithTTSv3(JSON.stringify(messageHistory), ttsVoice);
            
            if (result.statusCode === 200) {
                try {
                    const resultContent = result.message.content;
                    const messageResponse = getMessageResponseBySessionType(resultContent);

                    const sessionLogId 
                        = await createSessionLog(
                            messageResponse.message, 
                            getDetailContentBySessionType(resultContent), 
                            aiRoles.ai, 
                            null, 
                            messageResponse.endOfPrompt
                        );

                    // Tutor tipinde bir session ise, AI'nin cevabının title, section, round ve totalRound bilgilerini tutar.
                    const tutorAiMessageSection = isTutorSession ? {
                        title: messageResponse.title,
                        section: messageResponse.section,
                        round: messageResponse.round,
                        totalRound: messageResponse.totalRound,
                        currentState: messageResponse.currentState,
                        clue: messageResponse.clue,
                        sectionCompletionMessage: messageResponse.sectionCompletionMessage
                    } : null;

                    const aiMessage = {
                        id: sessionLogId,
                        type: 'ai',
                        content: messageResponse.message,
                        trySayingMessage: messageResponse.trySayingMessage,
                        translation: messageResponse.translation,
                        isLoading: false,
                        role: 'assistant',
                        audio: result.message.audio,
                        endOfPrompt: messageResponse.endOfPrompt,
                        tutor: tutorAiMessageSection ?? null
                    };
                    
                    setMessages(prev => [...prev, aiMessage]);
                    sendLog(EventLogs.CONVERSATIONAL_AI_MESSAGE_SEND);
                } catch (e) {
                    sendLog(EventLogs.CONVERSATIONAL_AI_MESSAGE_FAILED);
                }
            }
        } catch (e) {
            sendLog(EventLogs.CONVERSATIONAL_AI_MESSAGE_FAILED);
        } finally {
            setChatbotLoading(false);
        }
    };

    useEffect(() => {
        if(tutorAiUseCaseId !== -1){
            setIsTutorSession(aiUseCaseId === tutorAiUseCaseId);
        }
    }, [tutorAiUseCaseId]);

    // Kullanıcının yanlış yaptığı cümleleri düzeltmek için kullanılan prompt servisten getirilir.
    // Servisten getirilen promptlar, externalPrompts adında bir state'de saklanır.
    useEffect(() => {
        const fetchExternalPrompt = async (type) => {
            const result = await GetAiPromptByType(type);

            if (result.status === 200) 
                setExternalPrompts(prev => ({ ...prev, [type]: result.content }));
        };

        [externalPromptKeys.FIX_SENTENCE, externalPromptKeys.GET_SENTENCE_CLUE].forEach(type => {
            fetchExternalPrompt(type);
        });
    }, []);

    useEffect(() => {
        // Servisten getirilen AI Use Case prompt'unu çeker.
        // Bu prompt, AI'nin başlangıç mesajını oluşturmak için kullanılır.
        const fetchAIUseCase = async () => {
            try {
                const result = await GetBookUnitAiUseCasePrompt(aiUseCaseId, bookUnitId, getLanguageIdByLanguageKey(language));
                if (result.status === 200) {
                    const aiUseCase = JSON.parse(result.content);

                    setInitialLoading(false);
                    setChatbotLoading(true);
                    
                    // Eğer devam edilecek bir session varsa session oluşturma işlemini atlar.
                    // Bir session oluşturmadan, var olan session'a dev
                    if(lastSessionId){
                        await initializeChat(aiUseCase.prompt, lastSessionId);
                        return;
                    }

                    const sessionId = await createSession(member?.MemberId, aiUseCaseId);
                    if (sessionId) {
                        await initializeChat(aiUseCase.prompt, sessionId);
                    }
                }
            } catch (error) {} 
            finally {
                setInitialLoading(false);
            }
        };

        // Servisten getirilen AI Use Case Session Logs'ı çeker.
        // Bu logs, yarım kalan session'ın mesajlarını oluşturmak için kullanılır.
        // Readonly olarak gösterilirken, bu logs'ı çekeriz.
        const fetchAIUseCaseSessionLogs = async (sessionId) => {
            try {
                const result = await GetAIUseCaseSessionLogsBySessionId(sessionId);
                if(result.status === 200) {
                    const logs = JSON.parse(result.content);
                    const formattedMessages = logs
                        .map((log) => ({
                            id: log.id,
                            type: getAiRoleByRoleId(log.aiUseCaseRoleId),
                            content: log.contentText,
                            role: getAiRoleByRoleId(log.aiUseCaseRoleId),
                            isLoading: false,
                            isAutoCorrect: false,
                            translation: log.detail?.translation?.[localLangCode] || "",
                            ...(log.aiUseCaseRoleId === aiRoles.ai && { audio: log.audioUrl }),
                            ...(isTutorSession && log.detail && {
                                tutor: {
                                    section: log.detail?.section,
                                    round: log.detail?.round,
                                    totalRound: log.detail?.totalRound,
                                    title: log.detail?.title?.en?.length > 0 ? {
                                        en: log.detail?.title?.en,
                                        [localLangCode]: log.detail?.title?.[localLangCode]
                                    } : null,
                                    clue: log.detail?.clue?.[localLangCode]?.length > 0 ? log.detail?.clue?.[localLangCode] : null
                                }
                            })
                        }));

                    setMessages(formattedMessages);
                }
            } catch (error) {} 
            finally {
                setInitialLoading(false);
            }
        };

        // AI Use Case Session Id'si veya lastSessionId varsa, AI Use Case Session Logs'ı çeker.
        // Yoksa, AI Use Case Prompt'unu çeker.
        // Sebebi: Yarım kalan bir session varsa, o session'ın logs'ını çekeriz.
        // Yoksa, yeni bir session oluştururuz ve o session için gereken AI bilgilerini çekeriz.
        if(isTutorSession !== null){
            if (aiUseCaseSessionId || lastSessionId) {
                fetchAIUseCaseSessionLogs(aiUseCaseSessionId || lastSessionId);
            } else {
                fetchAIUseCase();
            }
        }
    }, [aiUseCaseSessionId, isTutorSession]);

    // Chat container'ın scroll'unu ayarlar.
    // AI Use Case Session Id'si varsa, chat container'ın scroll'unu sıfırlar.
    // Yoksa, chat container'ın scroll'unu en alta getirir.
    useEffect(() => {
        if (chatContainerRef.current) {
            if (aiUseCaseSessionId) {
                chatContainerRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                chatContainerRef.current.scrollTo({
                    top: chatContainerRef.current.scrollHeight,
                    behavior: 'smooth'
                });
            }
        }
    }, [
        messages, 
        aiUseCaseSessionId,
        chatbotLoading,
        transcribeLoading,
        showPlaceholder,
        showExample,
        showClue
    ]);

    // Placeholder'ın gösterilip gösterilmeyeceğini belirler.
    // Placeholder, AI'nin cevap verirken ve kullanıcı ses kaydederken gösterilir.
    useEffect(() => {
        const lastMessage = messages[messages.length - 1];
        const isLoading = chatbotLoading || transcribeLoading;

        // Eğer herhangi bir loading durumu varsa, placeholder'ı gösterme
        if (isLoading) {
            setShowPlaceholder(false);
            return;
        }

        // Loading durumları değiştiğinde, bir timeout ile placeholder'ı göster
        // Loading geçişleri arasında gecikme olabildiği için render problemi olmasın diye 300ms gecikme ekledik.
        const timeoutId = setTimeout(() => {
            const shouldShowPlaceholder = 
                !currentPlayingId &&
                messages.length > 0 && 
                lastMessage?.type === 'ai' &&
                !lastMessage?.endOfPrompt;

            // Eğer sectionCompletionMessage varsa, placeholder'ı geciktir
            if (shouldShowPlaceholder && lastMessage?.tutor?.sectionCompletionMessage) {
                setTimeout(() => {
                    setShowPlaceholder(true);
                }, 1500);
            } else {
                setShowPlaceholder(shouldShowPlaceholder);
            }
        }, 300);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [currentPlayingId, chatbotLoading, transcribeLoading, messages]);

    // Kullanıcı AI session'ını kapatmak istediğinde çalışır.
    // Yanlış cümleleri toplu halde servise göndermek için kullanılır.
    useEffect(() => {
        const handleModalClose = async (event) => {
            if (event.target.closest('#modal-back-button')) {
                sendLog(EventLogs.CONVERSATIONAL_AI_EXIT_MODAL_IMPRESSION);

                // Eğer endOfPrompt true ise, sadece yanlış cümleleri gönder
                if (messages[messages.length - 1]?.endOfPrompt) {
                    if (wrongSentences.length > 0) {
                        await createSessionCorrections();
                    }
                    return;
                }

                // endOfPrompt false ise ve onBackButtonClick varsa
                if (onBackButtonClick) {
                    event.preventDefault();
                    event.stopPropagation();
                    // Önce yanlış cümleleri gönder
                    if (wrongSentences.length > 0) {
                        await createSessionCorrections();
                    }
                    // Sonra modalı aç
                    onBackButtonClick();
                }
            }
        };

        document.addEventListener('click', handleModalClose, true);

        return () => {
            document.removeEventListener('click', handleModalClose, true);
        };
    }, [wrongSentences, onBackButtonClick, messages]);

    return (
        <>
            <SpeakerComponent />

            {initialLoading ? (
                <Loading className="mt-5" />
            ) : (
                <div id="conversational-chat-container" className="h-full flex flex-col relative bg-gray-50 overflow-x-hidden" role="main" aria-label="Chat Interface">
                    <div className="w-full max-w-3xl mx-auto px-4 py-3 text-center">
                        <div className="flex items-center justify-center gap-3">
                            {emoji && (
                                <motion.div
                                    initial={{ scale: 0.8 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        repeat: Infinity,
                                        repeatType: "reverse",
                                        duration: 1.8,
                                        ease: "easeInOut"
                                    }}
                                    className="text-2xl flex items-center justify-center"
                                >
                                    {emoji}
                                </motion.div>
                            )}
                            <h2 className="text-lg font-semibold text-base-text tracking-tight">
                                {title}
                            </h2>
                        </div>
                    </div>

                    {aiUseCaseSessionId && (
                        <div className="w-full max-w-3xl mx-auto px-1 mb-4">
                            <div className="flex items-center gap-2 mb-0.5">
                                <img src={ConversationBubble} alt="conversation" className="w-4 h-4" />
                                <h3 className="text-base font-bold text-base-text">
                                    {strings.speaking_lesson.conversational_ai.conversation_text}
                                </h3>
                            </div>
                            <p className="text-sm text-base-text">
                                {strings.speaking_lesson.conversational_ai.conversation_text_description}
                            </p>
                        </div>
                    )}

                    <div className={`w-full ${(aiUseCaseSessionId || messages[messages.length - 1]?.endOfPrompt) ? 'h-[calc(100%-120px)]' : 'h-[calc(100%-64px)]'} overflow-hidden overflow-x-hidden`}>
                        <div className={`w-full max-w-[768px] h-full mx-auto relative bg-white shadow-md 
                            ${(aiUseCaseSessionId || messages[messages.length - 1]?.endOfPrompt) ? 'rounded-xl' : 'rounded-t-xl'} overflow-x-hidden`}>
                            <div 
                                ref={chatContainerRef}
                                className={`absolute inset-0 overflow-y-auto overflow-x-hidden pl-4 pr-4 py-6 space-y-4 
                                    ${(aiUseCaseSessionId || messages[messages.length - 1]?.endOfPrompt) ? '' : 'mb-32'} scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                                role="log"
                                aria-live="polite"
                            >
                                {messages.map((message, index) => {

                                     // Mevcut user mesajından önceki en son AI mesajını bul
                                     const currentMessageIndex = messages.findIndex(msg => msg.id === message.id);
                                     const previousMessages = messages.slice(0, currentMessageIndex + 1);
                                     const lastAiMessage = [...previousMessages]
                                         .reverse()
                                         .find(msg => msg.role === "assistant" && msg.content);

                                    switch (message.type) {
                                        case 'ai':
                                            return (
                                                <div key={message.id}>
                                                    {message.tutor && (
                                                        <>
                                                            {message.tutor.sectionCompletionMessage && (
                                                                <motion.div
                                                                    initial={{ opacity: 0, y: 20 }}
                                                                    animate={{ opacity: 1, y: 0 }}
                                                                    transition={{ duration: 0.5 }}
                                                                    className="w-full max-w-[80%] mx-auto mb-6 bg-gradient-to-br from-[#FFF6E5] via-[#FFF9ED] to-[#FFFCF7] rounded-xl p-4 shadow-md border border-[#F7A766]/20"
                                                                >
                                                                    <p className="text-2xl text-center">🎉</p>
                                                                    <p className="text-sm text-[#7D6B5D] leading-relaxed text-center">
                                                                        {message.tutor.sectionCompletionMessage[language]}
                                                                    </p>
                                                                </motion.div>
                                                            )}
                                                            {message.tutor.title && 
                                                                <motion.div
                                                                    initial={{ opacity: 0, height: 0 }}
                                                                    animate={{ opacity: 1, height: 'auto' }}
                                                                    transition={{ 
                                                                        duration: 0.3, 
                                                                        delay: message.tutor?.sectionCompletionMessage ? 1.0 : 0 
                                                                    }}
                                                                >
                                                                    <TutorTitle
                                                                        text={strings.speaking_lesson.conversational_ai.section_title}
                                                                        title={message.tutor.title.en}
                                                                        section={message.tutor.section}
                                                                    />
                                                                </motion.div>
                                                            }
                                                            {shouldShowTutorRound(message, index, messages) && (
                                                                <motion.div
                                                                    initial={{ opacity: 0, height: 0 }}
                                                                    animate={{ opacity: 1, height: 'auto' }}
                                                                    transition={{ 
                                                                        duration: 0.3, 
                                                                        delay: message.tutor?.sectionCompletionMessage ? 1.0 : 0 
                                                                    }}
                                                                >
                                                                    <TutorQuestion
                                                                        text={strings.speaking_lesson.conversational_ai.question_title}
                                                                        questionNumber={message.tutor.round}
                                                                        totalQuestions={message.tutor.totalRound}
                                                                    />
                                                                </motion.div>
                                                            )}
                                                        </>
                                                    )}
                                                    <motion.div
                                                        initial={{ opacity: 0, height: 0 }}
                                                        animate={{ opacity: 1, height: 'auto' }}
                                                        transition={{ 
                                                            duration: 0.3, 
                                                            delay: message.tutor?.sectionCompletionMessage ? 1.0 : 0 
                                                        }}
                                                    >
                                                        <AIChatBubble
                                                            messageId={message.id}
                                                            content={message.content}
                                                            trySayingMessage={message.trySayingMessage}
                                                            translation={message.translation}
                                                            isLoading={message.isLoading}
                                                            onSpeakComplete={() => {
                                                                setCurrentPlayingId(null);
                                                                setCurrentAudioRef(null);
                                                            }}
                                                            onSpeakingStateChange={(isSpeaking, audioRef) => {
                                                                setCurrentPlayingId(isSpeaking ? message.id : null);
                                                                setCurrentAudioRef(isSpeaking ? audioRef : null);
                                                            }}
                                                            audioUrl={message.audio}
                                                            currentPlayingId={currentPlayingId}
                                                            stopCurrentAudio={stopCurrentAudio}
                                                            isReadOnly={!!aiUseCaseSessionId}
                                                            speakSentence={speakSentence}
                                                            outsideSpeakingCompleted={isSpeakingComplete}
                                                            ttsVoice={ttsVoice}
                                                            logAction={sendLog}
                                                            tutor={message?.tutor}
                                                            explainToMeText={strings.speaking_lesson.conversational_ai.explain_to_me}
                                                            onShowClue={setShowClue}
                                                        />
                                                    </motion.div>
                                                </div>
                                            );
                                        case 'user':
                                            return (
                                                <UserChatBubble
                                                    key={message.id}
                                                    content={message.content}
                                                    messageId={message.id}
                                                    onRefresh={handleRefresh}
                                                    isLoading={message.isLoading}
                                                    isReadOnly={!!aiUseCaseSessionId}
                                                    isLastMessage={index === messages.length - 1}
                                                    fixSentencePrompt={externalPrompts[externalPromptKeys.FIX_SENTENCE]}
                                                    endOfPrompt={messages[messages.length - 1]?.endOfPrompt}
                                                    isContinueSession={lastSessionId}
                                                    isAutoCorrect={message.isAutoCorrect}
                                                    setWrongSentences={setWrongSentences}
                                                    chatbotLoading={chatbotLoading}
                                                    sessionId={aiUseCaseSessionId || lastSessionId || useCaseSessionId}
                                                    lastAiMessageContent={lastAiMessage?.content || ''}
                                                    onGrammarCheckComplete={handleGrammarCheckComplete}
                                                />
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                                {chatbotLoading && (
                                    <AIChatBubble
                                        content=""
                                        isLoading={true}
                                    />
                                )}
                                {transcribeLoading && (
                                    <UserChatBubble
                                        content=""
                                        isLoading={true}
                                    />
                                )}
                                
                                {!messages[messages.length - 1]?.trySayingMessage && (
                                    <PlaceholderBubble
                                        type="default"
                                        content={strings.speaking_lesson.conversational_ai.press_the_speak_button}
                                        trySayingText={strings.speaking_lesson.conversational_ai.try_saying}
                                        show={!aiUseCaseSessionId && showPlaceholder && !currentPlayingId && !messages[messages.length - 1]?.endOfPrompt}
                                        externalPrompt={externalPrompts[externalPromptKeys.GET_SENTENCE_CLUE]}
                                        lastMessage={messages[messages.length - 1]}
                                        speakSentence={speakSentence}
                                        outsideSpeakingCompleted={isSpeakingComplete}
                                        stopSpeaking={stopSpeaking}
                                        ttsVoice={ttsVoice}
                                        logAction={sendLog}
                                        messageHistory={JSON.stringify(messages
                                            .filter(msg => msg.role !== 'system')
                                            .map(msg => ({
                                                role: msg.role,
                                                content: msg.content.trim()
                                            })))}
                                        onShowExampleChange={setShowExample}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {!aiUseCaseSessionId && !messages[messages.length - 1]?.endOfPrompt && (
                        <div className="absolute bottom-0 left-0 right-0 pb-5 bg-gradient-to-t from-gray-50 via-gray-50 to-transparent pt-16 overflow-x-hidden">
                            <div className="w-full max-w-[768px] mx-auto relative">
                                <RecordButton
                                    onRecordingStateChange={handleRecordingStateChange}
                                    isMicrophoneDisabled={chatbotLoading || transcribeLoading || initialLoading}
                                    isAnimateIcon={true}
                                    stopCurrentAudio={stopCurrentAudio}
                                    logAction={sendLog}
                                />
                            </div>
                        </div>
                    )}

                    {(aiUseCaseSessionId || messages[messages.length - 1]?.endOfPrompt) && (
                        <div className="w-full py-4 bg-gradient-to-t from-gray-50 via-gray-50 to-transparent">
                            <div className="w-full max-w-[768px] mx-auto px-4 flex justify-center">
                                <button
                                    type="button"
                                    onClick={() => navigate(url("speakinglesson.dailylesson"))}
                                    className="button primary-button max-w-[300px]"
                                >
                                    {strings.speaking_lesson.quiz.continue}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default IntelligentChat;