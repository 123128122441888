import { fetchPythonApi } from '../index';

export const Transcribe = async (formData) => {

    const url = process.env.REACT_APP_PYTHON_API_URL + '/ai/transcribe/';

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            const userMessage = await response.text();
            return userMessage;
        } else {
           return "Failed to reach the transcription API. Status code: " + response.status;
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

export const ChatbotWithTTS = async (botmessages, voice) => {

    const url = '/ai/chatbot_with_tts_v2/';

    const payload = {
        botmessages: botmessages,
        voice: voice
    };

    return await fetchPythonApi(url, payload);
}

export const ChatbotWithTTSv3 = async (botmessages, voice) => {

    const url = '/ai/chatbot_with_tts_v3/';

    const payload = {
        botmessages: botmessages,
        voice: voice,
        lang: process.env.REACT_APP_LANG_TR_CODE
    };

    return await fetchPythonApi(url, payload);
}

export const ChatbotWithTtsTutor = async (bookUnitId, ttsVoice, conversationHistory = [], userAnswer = "", checkUserAnswerStatus = null) => {

    const url = '/ai/chatbot_with_tts_tutor/';

    const payload = {
        bookUnitId: bookUnitId,
        conversationHistory: conversationHistory,
        userAnswer: userAnswer,
        voice: ttsVoice,
        checkUserAnswerStatus: checkUserAnswerStatus,
        language: process.env.REACT_APP_LANG_TR_CODE
    };

    return await fetchPythonApi(url, payload);
}

export const PromptAnalysisFlash = async (prompt) => {

    const url = '/ai/prompt_analysis_flash/';

    const payload = {
        prompt: prompt
    };

    return await fetchPythonApi(url, payload);
}