import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Lightbulb, LightbulbOff, Speaker, StopButton, Translate } from "../../../../../assets/svg";
import { EventLogs } from "../../../../../components/Constants";
import { LoadingSpinner } from "../../../../../components/Loading";
import { PromptAnalysisFlash } from "../../../../../services/AIUseCase/AIUseCasePythonService";

const PlaceholderBubble = ({ 
    content,
    trySayingText, 
    show, 
    externalPrompt, 
    lastMessage, 
    speakSentence,
    outsideSpeakingCompleted,
    stopSpeaking,
    ttsVoice,
    logAction = null,
    messageHistory = '',
    onShowExampleChange = null
}) => {

    const [showExample, setShowExample] = useState(false);
    const [showTranslation, setShowTranslation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [exampleResponse, setExampleResponse] = useState(null);
    const [hasRequested, setHasRequested] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);

    // Örnek cümle için API çağrısı
    const getExample = async () => {
        if (!lastMessage?.content || !externalPrompt || hasRequested) return;
        
        setIsLoading(true);
        try {
            const prompt = externalPrompt.replaceAll('##lastMessageContent##', lastMessage.content).replaceAll('##language##', process.env.REACT_APP_LANG_TR_CODE).replaceAll('##messageHistory##', JSON.stringify(messageHistory));
            const result = await PromptAnalysisFlash(prompt);
            
            if (result.status === 200) {
                try {
                    const cleanContent = result.content
                        .replace(/```json/g, '')
                        .replace(/```/g, '')
                        .trim();
                        
                    const response = JSON.parse(cleanContent);
                    onShowExampleChange?.(showExample);
                    setExampleResponse(response);
                    setHasRequested(true);
                } catch (parseError) {
                    setExampleResponse(null);
                }
            }
        } catch (error) {
            setExampleResponse(null);
        } finally {
            setIsLoading(false);
        }
    };

    // Örnek cümleyi seslendir
    const handleSpeak = async () => {
        try {
            if (!exampleResponse?.answer) return;
            
            if (isSpeaking) {
                setIsSpeaking(false);
                stopSpeaking();
                return;
            }

            setIsSpeaking(true);
            await speakSentence(exampleResponse.answer, ttsVoice || "");
            setIsSpeaking(false);
        } catch (error) {
            console.error('Error in handleSpeak:', error);
            setIsSpeaking(false);
        }
    };

    // Default bubble variants
    const defaultVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    };

    // Example bubble variants - Aşağıdan yukarı doğru animasyon
    const exampleVariants = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 20 }
    };

    const commonBubbleClasses = `
        w-fit max-w-[90%] px-4 py-3 rounded-2xl rounded-tr-sm
        border border-gray-300 border-dashed
        bg-white
    `;

    // Ana bubble için özel class
    const mainBubbleClasses = `
        w-fit px-4 max-w-[90%] py-3 rounded-2xl rounded-tr-sm
        border border-gray-300 border-dashed
        bg-white flex items-center
    `;

    // outsideSpeakingCompleted değiştiğinde isSpeaking'i günceller
    useEffect(() => {
        if (outsideSpeakingCompleted) {
            setIsSpeaking(false);
        }
    }, [outsideSpeakingCompleted]);

    // lastMessage değiştiğinde state'leri sıfırla
    useEffect(() => {
        setShowExample(false);
        setShowTranslation(false);
        setExampleResponse(null);
        setHasRequested(false);
        setIsSpeaking(false);
    }, [lastMessage?.content]);

    // showExample değiştiğinde parent'a bildir
    useEffect(() => {
        onShowExampleChange?.(showExample);
    }, [showExample, onShowExampleChange]);

    return (
        <AnimatePresence>
            {show && (
                <div className="flex flex-col items-end gap-2 my-1 pr-2">
                    {/* Ana Placeholder Bubble */}
                    <div className="flex flex-row justify-end items-start gap-1">
                        {/* Loading Spinner */}
                        <div className="mt-2 w-6 h-6 flex items-center justify-center">
                            {isLoading && <LoadingSpinner />}
                        </div>

                        {/* Bubble Content */}
                        <motion.div
                            variants={defaultVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.3 }}
                            className={mainBubbleClasses}
                        >
                            <p className="text-onboarding-border text-sm mr-1">{content}</p>
                            <button 
                                onClick={() => {
                                    if (!showExample && !hasRequested) {
                                        getExample();
                                    }
                                    setShowExample(!showExample);
                                    logAction?.(EventLogs.CONVERSATIONAL_AI_EXPLANATION_CLICKED);
                                }}
                            >
                                <img 
                                    src={showExample ? LightbulbOff : Lightbulb}
                                    className={`w-[22px] h-[22px] ${showExample ? 'text-primary' : 'text-base-text'}`}
                                    style={{
                                        filter: showExample
                                            ? 'invert(40%) sepia(95%) saturate(1752%) hue-rotate(199deg) brightness(100%) contrast(101%)'
                                            : 'none'
                                    }}
                                    alt="example"
                                />
                            </button>
                        </motion.div>
                    </div>

                    {/* Örnek Cümle Bubble */}
                    <AnimatePresence>
                        {showExample && !isLoading && (
                            <div className="flex flex-row justify-end items-start gap-1">
                                {/* Loading Spinner */}
                                <div className="mt-2 w-6 h-6 flex items-center justify-center">
                                    {isLoading && <LoadingSpinner />}
                                </div>
                                {/* Bubble Content */}
                                <motion.div
                                    variants={exampleVariants}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    transition={{ duration: 0.2 }}
                                    className={`${commonBubbleClasses}`}
                                >
                                    <div className="flex flex-col w-full">
                                        {!isLoading && exampleResponse && (
                                            <div className="flex flex-col w-full">
                                                <p className="text-onboarding-border text-sm mb-1">{trySayingText}</p>
                                                <p className="text-onboarding-border text-sm">{exampleResponse.answer}</p>
                                                <div className="flex flex-row items-center mt-2">
                                                    <button 
                                                        type="button"
                                                        onClick={handleSpeak}
                                                        className="transition-opacity p-1 hover:opacity-80"
                                                        aria-label={isSpeaking ? "Stop speaking" : "Start speaking"}
                                                    >
                                                        <img 
                                                            src={isSpeaking ? StopButton : Speaker}
                                                            className="w-[18px] h-[18px] text-base-text"
                                                            alt={isSpeaking ? "stop speaking" : "start speaking"}
                                                        />
                                                    </button>
                                                    <button 
                                                        onClick={() => setShowTranslation(!showTranslation)}
                                                        className="transition-all duration-200 hover:opacity-80"
                                                    >
                                                        <img 
                                                            src={Translate}
                                                            className="w-[18px] h-[18px] text-onboarding-border"
                                                            style={{
                                                                filter: showTranslation 
                                                                    ? 'invert(40%) sepia(95%) saturate(1752%) hue-rotate(199deg) brightness(100%) contrast(101%)'
                                                                    : 'none'
                                                            }}
                                                            alt="translate"
                                                        />
                                                    </button>
                                                </div>
                                                <AnimatePresence>
                                                    {showTranslation && exampleResponse.answerTranslation && (
                                                        <motion.div
                                                            initial={{ opacity: 0, height: 0 }}
                                                            animate={{ opacity: 1, height: 'auto' }}
                                                            exit={{ opacity: 0, height: 0 }}
                                                            transition={{ duration: 0.2 }}
                                                            className="mt-2 pt-2 border-t border-gray-200 w-full"
                                                        >
                                                            <p className="text-onboarding-border text-sm">
                                                                {exampleResponse.answerTranslation}
                                                            </p>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                        )}
                                    </div>
                                </motion.div>
                            </div>
                        )}
                    </AnimatePresence>
                </div>
            )}
        </AnimatePresence>
    );
};

export default PlaceholderBubble;