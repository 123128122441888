import { Outlet } from "react-router-dom";
import {
    AuthLayout,
    AutoLogin,
    AutoSignin,
    ForgotPassword,
    ForgotPasswordChangePassword,
    ForgotPasswordVerificationCode,
    Login,
    Register
} from '../pages/Auth';
import AuthRoute from "../pages/AuthRoute";
import { Dashboard, DashboardHowToLearnEnglish, DashboardLayout } from "../pages/Dashboard";
import { Demo, DemoLayout, DemoLevelSelection, DemoSuccess, DemoUnitSelection } from '../pages/Demo';
import { Error404Layout } from "../pages/Error404";
import { AddFamilyMember, Family, JoinFamily, JoinFamilyInvite, LearnTogetherHome, LearnTogetherLayout, MoneyboxTransfer, Reference } from "../pages/LearnTogether";
import {
    BuyChangeTeacherTime,
    BuyMissedCall,
    Checkout,
    Detail,
    Fail,
    Home,
    List as MarketEducationPackages,
    MarketLayout,
    PackageCatalog,
    Pending,
    RightCatalog,
    SubCategory,
    Successful
} from "../pages/Market";
import { MentorDetail, MentorLayout } from '../pages/Mentor';
import { Launch, MobileLayout } from "../pages/Mobile";
import { More, MoreLayout } from "../pages/More";
import { Onboarding, OnboardingSuccess } from '../pages/Onboarding';
import { OnboardingExerciseHome, OnboardingExerciseLayout, } from '../pages/OnboardingExercise';
import { Permission, PermissionLayout } from "../pages/Permission";
import { Profile, ProfileFollowList, ProfileLayout, ProfileSettings, Streak, StreakAwards } from "../pages/Profile";
import { CheckAuth } from "../pages/Redirect";
import { InnerCategory, Resources, ResourcesLayout, Videos } from '../pages/Resources';
import {
    AIPractice,
    AttendanceReport,
    ConversationalAI,
    ConversationalAIHistory,
    CorrectAndSpeak,
    DailyLessonHome,
    DailySentenceHome,
    EducationPrograms,
    EmbedAIPractice,
    ProgressReportHome,
    QuizHome,
    SentenceBank,
    SpeakingLab,
    SpeakingLessonHome,
    SpeakingLessonLayout,
    TermSchedule
} from "../pages/SpeakingLesson";
import {
    ChangePassword,
    ChangeTeacherTimeHome,
    FreezeHome,
    Holidays,
    MissedCallHome,
    PostponeHome,
    SendTicket,
    SupportHome,
    SupportLayout,
    Tickets
} from "../pages/Support";
import { TeacherDetails, TeacherLayout, TeacherList, TeacherRating } from "../pages/Teachers";
import {
    AI,
    Dialog,
    Grammar,
    Video,
    VideoFlowLayout,
    Home as VideoPracticeHome,
    VideoPracticeLayout,
    Word
} from "../pages/VideoPractice";

const routes = [
    {
        name: "home",
        path: "/",
        element: <CheckAuth />,
    },
    {
        name: "auth",
        path: "auth",
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <CheckAuth />
            },
            {
                name: "login",
                path: "login",
                element: <Login />
            },
            {
                name: "register",
                path: "register",
                element: <Register />
            },
            {
                name: "forgotpassword",
                path: "forgotpassword",
                element: <ForgotPassword />
            },
            {
                name: "verify",
                path: "verify",
                element: <ForgotPasswordVerificationCode />
            },
            {
                name: "changepassword",
                path: "changepassword",
                element: <ForgotPasswordChangePassword />
            },
        ]
    },
    {
        name: "autologin",
        path: "autologin/:jwt",
        element: <AutoLogin />
    },
    {
        name: "autosignin",
        path: "autosignin/:base64",
        element: <AutoSignin />
    },
    {
        name: "onboarding",
        path: "onboarding",
        auth: true,
        element: <Onboarding />,
    },
    {
        name: "success",
        path: "success",
        auth: true,
        element: <OnboardingSuccess />
    },
    {
        name: "onboardingexercise",
        path: "onboardingexercise/:dayIndex",
        auth: true,
        element: <OnboardingExerciseLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <OnboardingExerciseHome />
            }
        ]
    },
    {
        name: "resources",
        path: "resources",
        auth: true,
        element: <ResourcesLayout />,
        children: [
            {
                name: "resourcesdetail",
                index: true,
                auth: true,
                element: <Resources />
            },
            {
                name: "innercategory",
                path: "innercategory",
                auth: true,
                element: <InnerCategory />
            },
            {
                name: "videos",
                path: "videos",
                auth: true,
                element: <Videos />
            },
            {
                name: "videos",
                path: "videos/:level",
                auth: true,
                element: <Videos />
            }
        ]
    },
    {
        name: "demo",
        path: "demo",
        auth: true,
        element: <DemoLayout />,
        children: [
            {
                name: "demodetail",
                index: true,
                auth: true,
                element: <Demo />
            },
            {
                name: "unitselection",
                path: "unitselection",
                auth: true,
                element: <DemoUnitSelection />
            },
            {
                name: "levelselection",
                path: "levelselection",
                auth: true,
                element: <DemoLevelSelection />
            },
            {
                name: "success",
                path: "success",
                auth: true,
                element: <DemoSuccess />
            }
        ]
    },
    {
        name: "mentor",
        path: "mentor",
        auth: true,
        element: <MentorLayout />,
        children: [
            {
                name: "mentordetail",
                index: true,
                auth: true,
                element: <MentorDetail />
            },
        ]
    },
    {
        name: "dashboard",
        path: "dashboard",
        auth: true,
        element: <DashboardLayout />,
        children: [
            {
                index: true,
                element: <Dashboard />
            },
            {
                name: "howtolearn",
                path: "howtolearn",
                auth: true,
                element: <DashboardHowToLearnEnglish />
            }
        ]
    },
    {
        name: "market",
        path: "market",
        auth: true,
        element: <MarketLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                name: "packagecatalog",
                path: "packagecatalog",
                auth: true,
                element: <PackageCatalog />
            },
            {
                name: "packagecatalog",
                path: "packagecatalog/:selectedPackageInfo",
                auth: true,
                element: <PackageCatalog />
            },
            {
                name: "rightcatalog",
                path: "rightcatalog",
                auth: true,
                element: <RightCatalog />
            },
            {
                name: "checkout",
                path: "checkout",
                auth: true,
                element: <Checkout />
            },
            {
                name: "failed",
                path: "failed",
                auth: true,
                element: <Fail />
            },
            {
                name: "successful",
                path: "successful",
                auth: true,
                element: <Successful />
            },
            {
                name: "missedcall",
                path: "missedcall",
                auth: true,
                element: <BuyMissedCall />
            },
            {
                name: "changeteachertime",
                path: "changeteachertime",
                auth: true,
                element: <BuyChangeTeacherTime />
            },
            {
                name: "educationpackages",
                path: "educationpackages",
                auth: true,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <MarketEducationPackages />
                    },
                    {
                        name: "subcategory",
                        path: ":programId",
                        auth: true,
                        element: <SubCategory />
                    },
                    {
                        name: "detail",
                        path: "detail/:programId",
                        auth: true,
                        element: <Detail />
                    }
                ]
            },
        ]
    },
    {
        name: "mobile_market",
        path: "mobile_market/:lang/:key",
        element: <Home />
    },
    {
        name: "mobile_market_package_catalog",
        path: "mobile_market_package_catalog",
        element: <PackageCatalog />
    },
    {
        name: "mobile_market_right_catalog",
        path: "mobile_market_right_catalog",
        element: <RightCatalog />
    },
    {
        name: "mobile_market_checkout",
        path: "mobile_market_checkout",
        element: <Checkout />
    },
    {
        name: "market_pending",
        path: "market_pending/:base64",
        element: <Pending />
    },
    {
        name: "mobile_market_education_packages",
        path: "mobile_market_education_packages",
        element: <MarketEducationPackages />
    },
    {
        name: "mobile_market_sub_category",
        path: "mobile_market_sub_category/:programId",
        element: <SubCategory />
    },
    {
        name: "mobile_market_education_packages_detail",
        path: "mobile_market_education_packages_detail/:programId",
        element: <Detail />
    },
    {
        name: "speakinglesson",
        path: "speakinglesson",
        auth: true,
        element: <SpeakingLessonLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <SpeakingLessonHome />
            },
            {
                name: "dailylesson",
                path: "daily",
                auth: true,
                element: <DailyLessonHome />
            },
            {
                name: "ai",
                path: "ai",
                auth: true,
                element: <AIPractice />
            },
            {
                name: "chatbot",
                path: "chatbot/:useCaseId",
                auth: true,
                element: <AIPractice />
            },
            {
                name: "dailysentence",
                path: "dailysentence",
                auth: true,
                element: <DailySentenceHome />
            },
            {
                name: "selectedquiz",
                path: "selectedquiz/:bookUnitId",
                auth: true,
                element: <QuizHome />
            },
            {
                name: "quiz",
                path: "quiz",
                auth: true,
                element: <QuizHome />
            },
            {
                name: "attendancereport",
                path: "report",
                auth: true,
                element: <AttendanceReport />
            },
            {
                name: "progressreport",
                path: "progress",
                auth: true,
                element: <ProgressReportHome />
            },
            {
                name: "termschedule",
                path: "termschedule",
                auth: true,
                element: <TermSchedule />
            },
            {
                name: "educationpackages",
                path: "educationpackages",
                auth: true,
                element: <EducationPrograms />
            },
            {
                name: "sentencebank",
                path: "sentencebank",
                auth: true,
                element: <SentenceBank />
            },
            {
                name: "speakinglab",
                path: "speakinglab",
                auth: true,
                element: <SpeakingLab />
            },
            {
                name: "conversationalai",
                path: "conversationalai",
                auth: true,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        name: "chat",
                        path: "chat/:bookUnitAiUseCaseId/:aiUseCaseId",
                        auth: true,
                        element: <ConversationalAI />
                    },
                    {
                        name: "history",
                        path: "history",
                        auth: true,
                        element: <ConversationalAIHistory />
                    },
                    {
                        name: "correctandspeak",
                        path: "correctandspeak/:bookUnitId/:aiUseCaseId",
                        auth: true,
                        element: <CorrectAndSpeak />
                    }
                ]
            }
        ]
    },
    {
        name: "embed",
        path: "embed",
        auth: false,
        element: <Outlet />,
        children: [
            {
                name: "aichatbot",
                path: "aichatbot",
                auth: false,
                element: <EmbedAIPractice />
            }
        ]
    },
    {
        name: "teachers",
        path: "teachers",
        auth: true,
        element: <TeacherLayout />,
        children: [
            {
                name: "teacherslist",
                index: true,
                auth: true,
                element: <TeacherList />
            },
            {
                name: "detail",
                path: "detail/:teacherId",
                auth: true,
                element: <TeacherDetails />
            },
            {
                name: "rating",
                path: "rating",
                auth: true,
                element: <TeacherRating />
            }
        ]
    },
    {
        name: "support",
        path: "support",
        auth: true,
        element: <SupportLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <SupportHome />
            },
            {
                name: "sendticket",
                path: "sendticket",
                auth: true,
                element: <SendTicket />
            },
            {
                name: "tickets",
                path: "tickets",
                auth: true,
                element: <Tickets />
            },
            {
                name: "postpone",
                path: "postpone",
                auth: true,
                element: <PostponeHome />
            },
            {
                name: "changeteachertime",
                path: "changeteachertime",
                auth: true,
                element: <ChangeTeacherTimeHome />
            },
            {
                name: "freeze",
                path: "freeze",
                auth: true,
                element: <FreezeHome />
            },
            {
                name: "missedcall",
                path: "missedcall",
                auth: true,
                element: <MissedCallHome />
            },
            {
                name: "changepassword",
                path: "changepassword",
                auth: true,
                element: <ChangePassword />
            },
            {
                name: "holidays",
                path: "holidays",
                auth: true,
                element: <Holidays />
            }
        ]
    },
    {
        name: "permissions",
        path: "permissions",
        auth: true,
        element: <PermissionLayout />,
        children: [
            {
                index: true,
                element: <Permission />
            }
        ]
    },
    {
        name: "profile",
        path: "profile",
        auth: true,
        element: <ProfileLayout />,
        children: [
            {
                index: true,
                path: ":username",
                name: "profilesub",
                element: <Profile />
            },
            {
                path: "settings",
                name: "settings",
                element: <ProfileSettings />
            },
            {
                name: "followlist",
                path: "followlist/:username",
                element: <ProfileFollowList />
            },
            {
                name: "streak",
                path: "streak/:memberId",
                element: <Streak />
            },
            {
                name: "streakawards",
                path: "streakawards/:memberId",
                element: <StreakAwards />
            }
        ]
    },
    {
        name: "learntogether",
        path: "learntogether",
        auth: true,
        element: <LearnTogetherLayout />,
        children: [
            {
                index: true,
                element: <LearnTogetherHome />
            },
            {
                name: "reference",
                path: "reference",
                auth: true,
                element: <Reference />
            },
            {
                name: "family",
                path: "family",
                auth: true,
                element: <Family />
            },
            {
                name: "addfamilymember",
                path: "addfamilymember",
                auth: true,
                element: <AddFamilyMember />
            },
            {
                name: "moneyboxtransfer",
                path: "moneyboxtransfer",
                auth: true,
                element: <MoneyboxTransfer />
            }
        ]
    },
    {
        name: "more",
        path: "more",
        auth: true,
        element: <MoreLayout />,
        children: [
            {
                index: true,
                element: <More />
            }
        ]
    },
    {
        name: "mobile",
        path: "mobile",
        element: <MobileLayout />,
        children: [
            {
                name: "launch",
                path: "launch/:base64",
                element: <Launch />
            },
            {
                name: "joinfamily",
                path: "joinfamily",
                auth: true,
                element: <JoinFamily />
            },
            {
                name: "joinfamilyinvite",
                path: "joinfamilyinvite/:base64",
                auth: false,
                element: <JoinFamilyInvite />
            },
            {
                name: "dashboard",
                path: "dashboard",
                auth: true,
                element: <DashboardLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <Dashboard />
                    }
                ]
            },
            {
                name: "resources",
                path: "resources",
                auth: true,
                element: <ResourcesLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <Resources />
                    }
                ]
            },
            {
                name: "quiz",
                path: "quiz",
                auth: true,
                element: <SpeakingLessonLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <QuizHome />
                    }
                ]
            },
            {
                name: "chatbothome",
                path: "chatbothome",
                auth: true,
                element: <SpeakingLessonLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <AIPractice />
                    }
                ]
            },
            {
                name: "chatbothome",
                path: "chatbothome/:aiUseCaseType/:deeplink",
                auth: true,
                element: <SpeakingLessonLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <AIPractice />
                    }
                ]
            },
            {
                name: "invitefriends",
                path: "invitefriends",
                auth: true,
                element: <LearnTogetherLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <Reference />
                    }
                ]
            },
            {
                name: "teacherlist",
                path: "teacherlist",
                auth: true,
                element: <TeacherLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <TeacherList />
                    }
                ]
            },
            {
                name: "profile",
                path: "profile",
                auth: true,
                element: <ProfileLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        path: ":username/:memberId",
                        element: <Profile />
                    }
                ]
            },
            {
                name: "demolesson",
                path: "demolesson",
                auth: true,
                element: <DemoLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <Demo />
                    }
                ]
            },
            {
                name: "videopractice",
                path: "videopractice",
                auth: true,
                element: <VideoPracticeLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <VideoPracticeHome />
                    }
                ]
            },
            {
                name: "dailysentence",
                path: "dailysentence",
                auth: true,
                element: <SpeakingLessonLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <DailySentenceHome />
                    }
                ]
            },
            {
                name: "teacherlessonchange",
                path: "teacherlessonchange",
                auth: true,
                element: <SupportLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <ChangeTeacherTimeHome />,
                    },
                ]
            },
            {
                name: "moneyboxtransfer",
                path: "moneyboxtransfer",
                auth: true,
                element: <LearnTogetherLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <MoneyboxTransfer />
                    }
                ]
            },
            {
                name: "conversationalai",
                path: "conversationalai",
                auth: true,
                element: <SpeakingLessonLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <ConversationalAI />
                    }
                ]
            },
            {
                name: "dailylesson",
                path: "dailylesson/:termId/:lessonDate",
                auth: true,
                element: <SpeakingLessonLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <DailyLessonHome />
                    }
                ]
            },
            {
                name: "speakinglab",
                path: "speakinglab",
                auth: true,
                element: <SpeakingLessonLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        element: <SpeakingLab />
                    }
                ]
            }
        ]
    },
    {
        name: "videopractice",
        path: "videopractice",
        auth: true,
        element: <VideoPracticeLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <VideoPracticeHome />
            },
            {
                name: "videoflow",
                path: "",
                auth: true,
                element: <VideoFlowLayout />,
                children: [
                    {
                        name: "video",
                        path: "video/:videoId",
                        auth: true,
                        element: <Video />
                    },
                    {
                        name: "videos",
                        path: "videos",
                        auth: true,
                        element: <Video />
                    },
                    {
                        name: "word",
                        path: "word/:videoId",
                        auth: true,
                        element: <Word />
                    },
                    {
                        name: "words",
                        path: "words",
                        auth: true,
                        element: <Word />
                    },
                    {
                        name: "dialog",
                        path: "dialog/:videoId",
                        auth: true,
                        element: <Dialog />
                    },
                    {
                        name: "dialogs",
                        path: "dialogs",
                        auth: true,
                        element: <Dialog />
                    },
                    {
                        name: "grammar",
                        path: "grammar/:videoId",
                        auth: true,
                        element: <Grammar />
                    },
                    {
                        name: "grammars",
                        path: "grammars",
                        auth: true,
                        element: <Grammar />
                    },
                    {
                        name: "ai",
                        path: "ai/:videoId",
                        auth: true,
                        element: <AI />
                    }
                ]
            }
        ]
    },
    {
        name: "error404",
        path: "*",
        auth: true,
        element: <Error404Layout />
    },
    {
        name: "educationpackages",
        path: "educationpackages",
        auth: true,
        element: <Outlet />,
        children: [
            {
                index: true,
                auth: true,
                element: <MarketEducationPackages />
            },
            {
                name: "subcategory",
                path: ":programId",
                auth: true,
                element: <SubCategory />
            },
            {
                name: "detail",
                path: "detail/:programId",
                auth: true,
                element: <Detail />
            }
        ]
    },
]

const authMap = routes => routes.map(route => {
    if (route?.auth) {
        route.element = <AuthRoute>{route.element}</AuthRoute>;
    }
    if (route?.children) {
        route.children = authMap(route.children)
    }
    return route
})

export default authMap(routes);