import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import useAnalytics from "../hooks/useAnalytics";
import {DownArrow} from "../assets/svg";

const Accordion = (props) => {

    const {
        title,
        children,
        onClick,
        isOpen = false,
        disableOutsideClick = false,
        contentClassNames,
        scrollValue = 0,
        eventObject = {},
        isScrollable = true,
        iconClassName = "",
        isDownArrowIcon = false,
        customIcon = null,
        isOpenCloseIcon = true,
        openCloseCheckboxStatus = null,
        isButton = false,
        isClickable = true,
        iconContent,
        variant = "default",
        titleVariant = "default",
        isDisabled = false,
        icon = null, // Eğer icon gönderilirse yeşil border'li tasarım gösteriliyor
        action = () => {}
    } = props;

    const analytics = useAnalytics();

    const childrenRef = useRef();
    const scrollRef = useRef();
    const firstVisit = useRef(true);

    const [open, setOpen] = useState(isOpen);

    const handleTransitionEnd = () => {
        if (!open) {
            childrenRef?.current?.classList.add('hidden');
        }
    };

    useEffect(() => {
        if (open) {
            childrenRef?.current?.classList.remove('hidden');
            //Açılma eventinde istenen log atılıyor.
            if(Object.keys(eventObject).length > 0)
                analytics.useAnalytics(eventObject.event, eventObject.params);
        }
    }, [open]);

    useEffect(() => {
        scrollRef.current.scrollTop = scrollValue;
    }, [scrollValue])

    return (
        <OutsideClickHandler onOutsideClick={() => {!disableOutsideClick && setOpen(open => false)}}>
            <div className="outline-none text-sm mt-5 mb-5">
                <button
                    className={classNames(`${contentClassNames} flex justify-between border rounded-[10px] px-4 py-[7px] items-center transition duration-500 cursor-pointer relative group-focus-within:border-b-0 w-full pr-1`, {
                        "rounded-b-none" : open,
                        "border-box" : variant === "default",
                        "bg-[#fafafa]" : open && variant === "default",
                        "bg-white border-box" : !open && variant === "default",
                        "bg-white border-incompleted" : variant === "white",
                        "pl-7" : isOpenCloseIcon,
                        "pl-2" : !isOpenCloseIcon,
                        "hover:bg-[#fafafa]/70" : variant === "default" && isClickable,
                        "hover:bg-[#f7f7f7]" : variant === "white" && isClickable,
                        "pointer-events-none opacity-60" : isDisabled,
                        "!cursor-default": !isClickable,
                        "py-4" : icon
                    })}
                    onClick={(e) => {
                        if (e.target.closest('.custom-icon-wrapper')) {
                            return;
                        }

                        if(!isButton) {
                            onClick && onClick();
                            !onClick && setOpen(open => !open);
                        }
                        
                        action();
                        firstVisit.current = false;
                    }}
                >
                    {isOpenCloseIcon && !icon &&
                        <div
                            className={classNames("items-center inline-flex justify-center transform transition duration-500 absolute left-0 ml-2 mr-auto h-full", {
                                "rotate-90" : open
                            })}
                        >
                            <span className={classNames("material-symbols-outlined transition-colors duration-300", {
                                "text-primary" : open,
                                "text-base-text": !open
                            })}>
                                arrow_forward_ios
                            </span>
                        </div>
                    }

                    {icon && (
                        <div className="items-center inline-flex justify-center transform transition duration-500 absolute left-0 ml-2 mr-auto h-full">
                            <div className={classNames("w-9 h-9 p-1 rounded-full flex items-center justify-center relative", {
                                "border-[3px] border-green" : openCloseCheckboxStatus && !iconClassName,
                                "border-[3px] border-onboarding-bg" : !openCloseCheckboxStatus && !iconClassName,
                                [iconClassName]: iconClassName
                            })}>
                                <img src={icon} className="w-[90%] h-[90%]" alt="icon" />
                                {!iconClassName &&
                                    <div className={classNames("absolute -bottom-[5px] -right-[5px] w-4 h-4 rounded-full flex items-center justify-center", {
                                        "bg-green" : openCloseCheckboxStatus,
                                        "bg-onboarding-bg" : !openCloseCheckboxStatus
                                    })}>
                                        <span className={classNames("material-symbols-outlined text-[16px]", {
                                            "text-white" : openCloseCheckboxStatus,
                                            "text-base-text" : !openCloseCheckboxStatus
                                        })}>check</span>
                                    </div>
                                }
                            </div>
                        </div>
                    )}

                    <div className={classNames({
                        "flex items-center gap-2" : iconContent,
                        "ml-6" : icon && !iconClassName,
                        "ml-10" : icon && iconClassName,
                        "ml-2" : !icon
                    })}>
                        {iconContent}
                        <p className={classNames("font-bold text-start transition-colors duration-300", {
                            "text-primary" : open,
                            "text-[14px]" : titleVariant === "default",
                            "text-[16px]" : titleVariant === "normal"
                        })}>
                            {title}
                        </p>
                    </div>
                    
                    {(isDownArrowIcon && !open && openCloseCheckboxStatus === null) &&
                        <div
                            className={classNames("", {
                                "mr-2" : isOpenCloseIcon
                            })}
                        >
                           <img src={DownArrow} className="-rotate-90" width={14} height={14} alt="down-arrow" />
                        </div>
                    }

                    {(customIcon && !isDownArrowIcon && openCloseCheckboxStatus === null) && customIcon}

                    {icon && children && !iconClassName &&
                        <div
                            className={classNames("items-center inline-flex justify-center transform transition duration-500 mr-2 ml-auto h-full", {
                                "rotate-90" : open
                            })}
                        >
                            <span className={classNames("material-symbols-outlined transition-colors duration-300", {
                                "text-primary" : open,
                                "text-base-text": !open
                            })}>
                                arrow_forward_ios
                            </span>
                        </div>
                    }

                    {openCloseCheckboxStatus !== null && (!icon || iconClassName) &&
                        <div
                            className={classNames("flex text-center justify-center mr-2 w-5 h-5 rounded-full", {
                                "bg-onboarding-border" : !openCloseCheckboxStatus,
                                "bg-green": openCloseCheckboxStatus
                            })}
                        >
                            <span className="material-symbols-outlined text-white text-[22px]">check</span>
                        </div>
                    }
                </button>
                <div
                    ref={scrollRef}
                    className={classNames(`${contentClassNames || ""} transition-all duration-500  rounded-[10px] rounded-t-none px-4`, {
                        "bg-[#fafafa] border-box" : variant === "default",
                        "bg-white border-incompleted" : variant === "white",
                        "border-x border-b pt-2" : open,
                        "overflow-y-scroll max-h-screen" : isScrollable && open,
                        "max-h-0" : !open
                    })}
                    onTransitionEnd={handleTransitionEnd}
                >
                    {open && (
                        <div
                            ref={childrenRef}
                            className={`${isScrollable ? 'max-h-[600px]' : ''} ${!open && 'animate-fadeOut'} ${firstVisit.current ? 'hidden' : ''}`}
                        >
                            {children}
                        </div>
                    )}
                    {!open && <></>}
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default Accordion;