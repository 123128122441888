import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as HistoryIcon } from "../../../assets/svg/speaking_lesson/conversational_ai/history.svg";
import { ConversationalAILogTitles, EventLogs } from "../../../components/Constants";
import { Loading } from "../../../components/Loading";
import { ModalFullScreenSlide } from "../../../components/Modals";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import { url } from "../../../routes/utility";
import { GetBookUnitAIUseCase, GetBookUnitAiUseCaseByNearestLesson } from "../../../services/ConversationalAI/ConversationalAIService";
import { getLanguageIdByLanguageKey } from "../../../utils";
import { ChatContinueOrEndModal } from "../components";
import IntelligentChat from "../components/IntelligentChat/IntelligentChat";

const ConversationalAI = () => {

    const { bookUnitAiUseCaseId, aiUseCaseId } = useParams();
    const [searchParams] = useSearchParams();
    const isStorytelling = searchParams.get('isStorytelling') === 'true';

    const strings = useLocalization();
    const { member } = useAuth();
    const navigate = useNavigate();
    const analytics = useAnalytics();

    const language = useSelector(state => state.localizationStore.language);
    const selectedTermId = useSelector(state => state.termStore.termId) || null;
    const selectedDate = localStorage.getItem("selectedDate") || null;

    // AI ile ilgili kitap verilerini çeker.
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    // Params'dan bookUnitAiUseCaseId gelmediyse 
    const [stableBookUnitAiUseCaseId, setStableBookUnitAiUseCaseId] = useState(bookUnitAiUseCaseId || null);
    
    // Content kısmını gösterir.
    const [isContentVisible, setIsContentVisible] = useState(true);
    // AI Chat'i gösterir.
    const [showAIChat, setShowAIChat] = useState(false);
    // Devam edilecek bir session varsa ChatContinueOrEndModal'i gösterir.
    const [showChatContinueOrEndModal, setShowChatContinueOrEndModal] = useState(false);
    // Session'a devam edilecekse doldurulacak.
    const [lastSessionId, setLastSessionId] = useState(null);
    // Geri butonuna tıklandığında çalışır
    const [showBackButtonModal, setShowBackButtonModal] = useState(false);

    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.3,
                when: "beforeChildren",
                staggerChildren: 0.15,
                delayChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.3 }
        }
    };

    // Log Mesajını replace ederek log mesajını gönderir.
    const sendLog = (log) => {
        const logTitle = ConversationalAILogTitles[aiUseCaseId || data?.aiUseCaseId];
        const logMessage = log.replace("#CONVERSATIONAL_AI_TYPE#", logTitle);
        analytics.useAnalytics(logMessage);
    }

    // "Başla" butonuna tıklandığında çalışır. Devam edilecek bir session varsa ChatContinueOrEndModal'i gösterir. Yoksa AI Chat'i gösterir.
    const handleStartClick = () => {
        sendLog(EventLogs.CONVERSATIONAL_AI_START_BUTTON_CLICK);

        if(data?.lastSessionId) {
            sendLog(EventLogs.CONVERSATIONAL_AI_CONTINUE_SCREEN_IMPRESSION);
            setShowChatContinueOrEndModal(true);
        } else {
            handleStartAIChat();
        }
    };

    // AI Chat'i gösterir. Content kısmını gizler.
    const handleStartAIChat = () => {
        setShowAIChat(true);
        
        setTimeout(() => {
            setIsContentVisible(false);
        }, 500);
    };

    // Servisten AI ile ilgili kitap verilerini çeker.
    const fetchBookUnitAIUseCase = async () => {
        setLoading(true);
        try {
            const model = {
                bookUnitAiUseCaseId: +stableBookUnitAiUseCaseId,
                languageTitleType: getLanguageIdByLanguageKey(language),
                memberId: member?.MemberId,
                termId: selectedTermId,
                lessonDate: selectedDate
            };

            const result = await GetBookUnitAIUseCase(model);
            if (result.status === 200) {
                setData(JSON.parse(result.content));
            }
        } catch (e) {} 
        finally {
            setLoading(false);
        }
    };

    // Servisten AI ile ilgili kitap verilerini çeker.
    // Params'dan bookUnitAiUseCaseId gelmediyse ve en yakın ders varsa bu fonksiyon çalışır.
    const fetchBookUnitAiUseCaseByNearestLesson = async () => {
        const result = await GetBookUnitAiUseCaseByNearestLesson(member?.MemberId, isStorytelling);
        if (result.status === 200) {
            const data = JSON.parse(result.content);
            setStableBookUnitAiUseCaseId(data?.bookUnitAiUseCaseId);
        } else {
            navigate(url("dashboard"));
        }
    };

    // AIChat componenti içerisine gönderilir. Content'i tekrar render eder. AI Chat'i kapatır.
    const handleCloseAIChat = () => {
        setShowAIChat(false);
        setIsContentVisible(true);
        // Modal kapandığında servisi tekrar tetikle
        fetchBookUnitAIUseCase();
    };

    // ChatContinueOrEndModal'ı kapatır ve AI tarafına devam etmek için çalışır.
    const handleContinue = () => {
        sendLog(EventLogs.CONVERSATIONAL_AI_CONTINUE_CLICK);
        setShowChatContinueOrEndModal(false);
        setLastSessionId(data?.lastSessionId);
        handleStartAIChat();
    };

    // ChatContinueOrEndModal'ı kapatır ve AI tarafını yeniden başlatmak için çalışır.
    const handleStartOver = () => {
        sendLog(EventLogs.CONVERSATIONAL_AI_START_OVER_CLICK);
        setShowChatContinueOrEndModal(false);
        setLastSessionId(null);
        handleStartAIChat();
    };

    // Back button modalında yeniden başla butonuna tıklandığında çalışır
    const handleBackButtonStartOver = () => {
        setShowBackButtonModal(false);
        setLastSessionId(null);
        handleCloseAIChat();
    };

    // Servisten AI ile ilgili kitap verilerini çeker.
    useEffect(() => {
        if (stableBookUnitAiUseCaseId) {
            fetchBookUnitAIUseCase();
        } else {
            fetchBookUnitAiUseCaseByNearestLesson();
        }
    }, [stableBookUnitAiUseCaseId, language]);

    useEffect(() => {
        if (data?.content) {
            setIsContentVisible(true);
        }
    }, [data]);

    useEffect(() => {
        if (showAIChat) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup function
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showAIChat]);

    useEffect(() => {
        if(aiUseCaseId || data?.aiUseCaseId) {
            sendLog(EventLogs.CONVERSATIONAL_AI_HOME_SCREEN_VIEWED);
        }
    }, [aiUseCaseId, data?.aiUseCaseId]);

    return (
        <>
            {isContentVisible && (
                <div className="main-content overflow-hidden">
                    <div className="flex flex-col h-[calc(100vh-220px)] bg-white mt-5">
                        {loading && <Loading />}
                        
                        {!loading && 
                            <>
                                <div className="flex flex-col h-full">
                                    <AnimatePresence>
                                        {isContentVisible && (
                                            <motion.div
                                                variants={containerVariants}
                                                initial="hidden"
                                                animate="visible"
                                                className="flex flex-col h-full"
                                            >
                                                <div className="flex-shrink-0">
                                                    <motion.div 
                                                        variants={itemVariants}
                                                        className="w-full flex items-center ml-2 justify-between"
                                                    >
                                                        <button 
                                                            type="button"
                                                            className="border border-onboarding-border hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base gap-2 h-8 max-w-max px-3 flex flex-row items-center justify-center rounded-[10px]"
                                                            onClick={() => navigate(url("speakinglesson.dailylesson"))}
                                                        >
                                                            <span className="material-symbols-outlined">keyboard_return</span>
                                                            {strings.ai_chatbot.back}
                                                        </button>
                                                        
                                                        {data?.isSessionHistoryExits && 
                                                            <button 
                                                                type="button"   
                                                                className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200 active:bg-gray-200"
                                                                onClick={() => {
                                                                    navigate(url("speakinglesson.conversationalai.history"), { 
                                                                        state: { 
                                                                            bookUnitId: data?.bookUnitId,
                                                                            aiUseCaseId: data?.aiUseCaseId 
                                                                        } 
                                                                    })
                                                                }}
                                                            >
                                                                <HistoryIcon className="w-6 h-6 hover:text-box transition-colors duration-200" />
                                                            </button>
                                                        }
                                                    </motion.div>

                                                    <motion.div variants={itemVariants} className="text-center mb-2 -mt-3">
                                                        <div className="w-14 h-14 md:w-24 md:h-24 mx-auto flex items-center justify-center">
                                                            <motion.div
                                                                initial={{ scale: 0.8 }}
                                                                animate={{ scale: 1 }}
                                                                transition={{
                                                                    repeat: Infinity,
                                                                    repeatType: "reverse",
                                                                    duration: 1.8,
                                                                    ease: "easeInOut"
                                                                }}
                                                                style={{
                                                                    willChange: "transform",
                                                                    display: 'inline-flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    height: '100%',
                                                                    lineHeight: 1,
                                                                    gap: '2px'
                                                                }}
                                                                className="text-[40px] md:text-[56px] transform-gpu whitespace-nowrap"
                                                            >
                                                                {data?.content?.emoji}
                                                            </motion.div>
                                                        </div>
                                                        <h2 className="text-[16px] md:text-[18px] font-bold -mt-2">
                                                            {data?.content?.title || data?.content?.unitTitle}
                                                        </h2>
                                                    </motion.div>
                                                </div>

                                                <motion.div 
                                                    id="conversational-ai-content"
                                                    variants={itemVariants}
                                                    className="flex-1 min-h-0 md:space-y-6 overflow-hidden"
                                                >
                                                    <div className="h-full overflow-y-auto pr-2 flex flex-col gap-1 pb-0">
                                                        {data?.sections?.length > 0 ? (
                                                            data.sections.map((item, index) => (
                                                                <motion.div
                                                                    key={index}
                                                                    variants={itemVariants}
                                                                    className="mb-2"
                                                                >
                                                                    <h3 className="text-[14px] md:text-[16px] font-bold mb-2">{item.sectionTitle}</h3>
                                                                    <div className="bg-box p-4 rounded-lg flex items-center">
                                                                        <div className="w-2.5 h-2.5 rounded-full bg-green flex-shrink-0" />
                                                                        <p className="text-[14px] md:text-base ml-3">{item.sectionDescription}</p>
                                                                    </div>
                                                                </motion.div>
                                                            ))
                                                        ) : (
                                                            <>
                                                                {data?.content?.description && (
                                                                    <motion.div variants={itemVariants} className="mb-3">
                                                                        <h3 className="text-[14px] md:text-base font-bold mb-2">
                                                                            {strings.speaking_lesson.conversational_ai.description}
                                                                        </h3>
                                                                        <div className="bg-box p-4 rounded-lg flex items-center">
                                                                            <div className="w-2.5 h-2.5 rounded-full bg-green flex-shrink-0" />
                                                                            <p className="text-[14px] md:text-base ml-3">{data.content.description}</p>
                                                                        </div>
                                                                    </motion.div>
                                                                )}
                                                                {data?.content?.beginningOfTheStory && (
                                                                    <motion.div variants={itemVariants} className="mb-3">
                                                                        <h3 className="text-[14px] md:text-base font-bold mb-2">
                                                                            {strings.speaking_lesson.conversational_ai.beginning_of_story}
                                                                        </h3>
                                                                        <div className="bg-box p-4 rounded-lg flex items-center">
                                                                            <div className="w-2.5 h-2.5 rounded-full bg-green flex-shrink-0" />
                                                                            <p className="text-[14px] md:text-base ml-3">{data.content.beginningOfTheStory}</p>
                                                                        </div>
                                                                    </motion.div>
                                                                )}
                                                                {data?.content?.goal && (
                                                                    <motion.div variants={itemVariants} className="mb-3">
                                                                        <h3 className="text-[14px] md:text-base font-bold mb-2">
                                                                            {strings.speaking_lesson.conversational_ai.goal}
                                                                        </h3>
                                                                        <div className="bg-box p-4 rounded-lg flex items-center">
                                                                            <div className="w-2.5 h-2.5 rounded-full bg-green flex-shrink-0" />
                                                                            <p className="text-[14px] md:text-base ml-3">{data.content.goal}</p>
                                                                        </div>
                                                                    </motion.div>
                                                                )}
                                                                {data?.content?.alsoTry && (
                                                                    <motion.div variants={itemVariants} className="mb-3">
                                                                        <h3 className="text-[14px] md:text-base font-bold mb-2">
                                                                            {strings.speaking_lesson.conversational_ai.also_try}
                                                                        </h3>
                                                                        <div className="bg-box p-4 rounded-lg flex items-center">
                                                                            <div className="w-2.5 h-2.5 rounded-full bg-green flex-shrink-0" />
                                                                            <p className="text-[14px] md:text-base ml-3">{data.content.alsoTry}</p>
                                                                        </div>
                                                                    </motion.div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </motion.div>

                                                <div className="flex-shrink-0">
                                                    <motion.div
                                                        variants={itemVariants}
                                                        className="pt-0 pb-1 md:py-2 space-y-1 md:space-y-2 btn-outer-div"
                                                    >
                                                        {data?.isCorrectionExists && (
                                                            <button 
                                                                className="button button-mt primary-button-outline"
                                                                onClick={() => {
                                                                    sendLog(EventLogs.CONVERSATIONAL_AI_CORRECT_AND_SPEAK_CLICK);
                                                                    navigate(url("speakinglesson.conversationalai.correctandspeak", {
                                                                        bookUnitId: data?.bookUnitId,
                                                                        aiUseCaseId: data?.aiUseCaseId
                                                                    }));
                                                                }}
                                                            >
                                                                {strings.speaking_lesson.daily_lesson.correct_and_speak}
                                                            </button>
                                                        )}

                                                        <button 
                                                            className="button button-mt primary-button"
                                                            onClick={handleStartClick}
                                                        >
                                                            {strings.speaking_lesson.quiz.start}
                                                        </button>
                                                    </motion.div>
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            </>
                        }
                    </div>

                    <div className="left-margin-bottom"></div>
                </div>
            )}

            <AnimatePresence mode="wait">
                {showAIChat && (
                    <ModalFullScreenSlide 
                        id="conversational-ai-modal"
                        key="modal" 
                        handleCloseClick={handleCloseAIChat}
                    >
                        <div className="h-full flex flex-col">
                            <div className="flex-1 overflow-y-auto">
                                <IntelligentChat 
                                    title={data?.content?.title || data?.content?.unitTitle}
                                    emoji={data?.content?.emoji}
                                    bookUnitId={data?.bookUnitId}
                                    aiUseCaseId={data?.aiUseCaseId}
                                    lastSessionId={lastSessionId}
                                    onBackButtonClick={() => setShowBackButtonModal(true)}
                                />
                            </div>
                        </div>
                    </ModalFullScreenSlide>
                )}
            </AnimatePresence>

            {/* Yarım kalan session varsa açılan modal */}
            <ChatContinueOrEndModal 
                type="start"
                showModal={showChatContinueOrEndModal}
                handleCloseModal={() => setShowChatContinueOrEndModal(false)}
                handleContinue={handleContinue}
                handleStartOver={handleStartOver}
            />

            {/* Chat içerisindeki geri butonuna tıklandığında açılan modal */}
            <ChatContinueOrEndModal 
                type="back"
                showModal={showBackButtonModal}
                handleCloseModal={() => setShowBackButtonModal(false)}
                handleContinue={() => {
                    sendLog(EventLogs.CONVERSATIONAL_AI_EXIT_CLICKED);
                    handleBackButtonStartOver();
                }}
                handleStartOver={() => {
                    sendLog(EventLogs.CONVERSATIONAL_AI_EXIT_CANCEL_CLICKED);
                    setShowBackButtonModal(false);
                }}
            />
        </>
    );
};

export default ConversationalAI;